import React from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native';

import { FontIcon, Modal, ModalFrame, Text, useTheme } from '@ere-uilib/index';

import { useStyles } from './useStyles';

interface MandateModalProps {
  isMandateModalVisible: boolean;
  onCloseModal: () => void;
  content: () => React.ReactNode;
  renderLeftButton?: () => React.ReactNode;
}

export const MandateModal: React.FC<React.PropsWithChildren<MandateModalProps>> = ({
  isMandateModalVisible,
  onCloseModal,
  content,
  renderLeftButton = undefined
}) => {
  const styles = useStyles(!!renderLeftButton?.());
  const theme = useTheme();
  return (
    <Modal visible={isMandateModalVisible}>
      <ModalFrame>
        <View style={styles.modalContentWrapper}>
          <View style={styles.actionsContainer}>
            {renderLeftButton?.()}
            <TouchableOpacity
              onPress={onCloseModal}
              style={styles.closeIcon}>
              <FontIcon
                color={theme.colors.text.primary.c500}
                name="fermer"
                size={theme.metrics.iconSizes.xxm}
              />
            </TouchableOpacity>
          </View>
          {content()}
        </View>
      </ModalFrame>
    </Modal>
  );
};
