import { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';
import { ProfitSharingIncentiveLegalFrameworkEnum } from '@constants/ProfitSharingIncentive';

export const useLabel = () => {
  const { formatMessage, formatCurrencyNumber } = useTranslation();

  const label = useMemo(
    () => ({
      getParagraph: (availableAmount: number | null, suggestedAmount: number | null, isPPV?: boolean) => {
        const labelId =
          (isPPV ? "PIChoixInvestPaimentPPVCardInfo" : (suggestedAmount === null && 'PIChoixInvestPaimentPPVAide')) ||
          (suggestedAmount && 'PIDefinitionBesoinAbondementWithOptimiserAndAbondementAvailable') ||
          'PIDefinitionBesoinAbondementWithOptimiserAndAbondementNOTAvailable';

        return formatMessage({
          id: labelId,
          values: {
            availableAmount: formatCurrencyNumber({ value: availableAmount || 0 }),
            suggestedAmount: formatCurrencyNumber({ value: suggestedAmount || 0 })
          }
        });
      },
      link: formatMessage({ id: 'PIDefinitionBesoinAbondementKnowMore' })
    }),
    [formatCurrencyNumber, formatMessage]
  );

  return label;
};
