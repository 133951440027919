import React, { useCallback, useMemo, useState } from 'react';
import { View, Animated, Easing } from 'react-native';

import { Title, Modal } from '@ere-uilib/atoms';
import {
  ModalFrame,
  SimpleButton,
  ActivableButtonSet,
  GenericCardWidthThreeParams,
} from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';
import { AccountState } from '@ere-uilib/types';
import { getPlansNameByCompany } from '@hooks/useGetCompanyPlansName';

import { Props } from './interface';
import { useStyles } from './useStyles';

const HEADER_HEIGHT = 45;

const TitleAnimated = Animated.createAnimatedComponent(Title);

export const DashboardHeader: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    userAccounts,
    selectedCompany,
    onSelectCompany,
    navigation,
    totalAmounAt,
    modalContainerStyle,
    contentStyle,
    companySelectionButtonStyle,
    scrollValue = 0,
  } = props;

  const { formatMessage, formatDate, formatCurrencyNumber } = useTranslation();
  const styles = useStyles();

  const [isVisible, setIsVisible] = useState(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();
  const isMultipleCompanies = (userAccounts?.companies?.length || 0) > 1;

  const { opacity, marginTop } = useMemo(() => {
    const clamp = Animated.diffClamp(new Animated.Value(scrollValue), 0, HEADER_HEIGHT);

    const interpolate = (outputRange: number[] | string[]) => clamp.interpolate({
      inputRange: [0, HEADER_HEIGHT],
      outputRange,
      easing: Easing.quad,
      extrapolate: 'clamp'
    });

    const opacity = interpolate([1, 0],);
    const marginTop = interpolate([5, -HEADER_HEIGHT]);

    return { opacity, marginTop };
  }, [scrollValue]);

  const onPressCompany = (company: AccountState) => {
    onSelectCompany(company, navigation);
  };

  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }

  const onHandleChoiceModal = useCallback(() => {
    return (
      <Modal visible={isVisible}>
        <ModalFrame
          desktopPosition="center"
          maxDesktopWidth={550}
          mobilePosition="bottom"
          onClose={() => setIsVisible(false)}
        >
          <View style={[styles.modalContainerStyle, modalContainerStyle]}>
            <View style={[styles.contentStyle, contentStyle]}>
              <Title
                style={styles.selectionTitle}
                variant="t6"
                weight="light"
              >
                {formatMessage({ id: 'MultiEntreprise_Choice_List_title' })}
              </Title>
              {userAccounts?.companies.map((company: AccountState, index: number) => {
                const isSelected = company?.companyId === selectedCompany?.companyId;
                return (
                  <ActivableButtonSet
                    buttonStyle={styles.activableButtonSetStyle}
                    hovered={index === hoveredIndex ? hovered : false}
                    isSelected={isSelected}
                    key={index}
                    onChange={() => {
                      onPressCompany(company);
                    }}
                    setHovered={(hoveredValue) => handleHovered(hoveredValue, index)}
                  >
                    <GenericCardWidthThreeParams
                      hovered={index === hoveredIndex ? hovered : false}
                      isSelected={isSelected}
                      subtitle={getPlansNameByCompany(company)}
                      title={company.companyName}
                      value={formatCurrencyNumber({ value: company.totalAmount })}
                    />
                  </ActivableButtonSet>
                );
              })}
            </View>
          </View>
        </ModalFrame>
      </Modal>
    )
  }, [
    isVisible,
    userAccounts,
    hovered,
    hoveredIndex,
    styles,
    selectedCompany,
    onPressCompany,
    formatMessage,
    formatCurrencyNumber
  ]);

  return (
    <>
      <Animated.View style={[styles.topLevelContainerStyle, !!scrollValue && styles.containerShadow]}>
        <View style={styles.centreContainerStyle}>
          <TitleAnimated
            style={{ opacity }}
            variant='t6'
          >
            {formatMessage({ id: 'saving_title' })}
          </TitleAnimated>
          <TitleAnimated
            style={{ opacity }}
            variant='t8'
            weight='light'
          >
            {totalAmounAt && formatMessage({
              id: 'saving_sub-title',
              values: {
                variable: formatDate({
                  value: totalAmounAt,
                  options: {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  },
                }),
              },
            })}
          </TitleAnimated>
          <TitleAnimated
            style={[styles.amountStyle, { marginTop }]}
            variant='t3'
            weight='bold'
          >
            {formatCurrencyNumber({ value: selectedCompany?.totalAmount || 0 })}
          </TitleAnimated>
        </View>
        <SimpleButton
          containerStyle={[styles.companySelectionButtonStyle, companySelectionButtonStyle]}
          design={isMultipleCompanies ? 'outlined' : 'solid'}
          disabled={!isMultipleCompanies}
          onPress={() => setIsVisible(true)}
          rightIcon={(isMultipleCompanies && 'chevron-bas') || undefined}
          rightIconStyle={styles.buttonRightIcon}
          testId='select_company'
          title={selectedCompany?.companyName}
          titleTextProps={{ numberOfLines: 1 }}
        />
      </Animated.View>
      {onHandleChoiceModal()}
    </>
  );
};
