import React from 'react';
import { Pressable, View } from 'react-native';

import { FontIcon, Title } from '@ere-uilib/atoms';
import { useScreenSizes } from '@ere-uilib/styles';
import { createUseStyles } from '@ere-uilib/styles/createUseStyles';
import { ThemeType, useTheme } from '@ere-uilib/styles/theme';
import { theme as staticTheme } from '@ere-uilib/theme';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const HeaderResponse: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  titleRight,
  amount,
  hideAmount,
  shouldFormatAsPlusMinus = true,
  iconName,
  iconColor = staticTheme.colors.white,
  iconSize = staticTheme.iconSize.medium,
  customContainerStyle,
  customTitleStyle,
  customAmountStyle,
  customIconContainerStyle,
  darkText = false,
  isDisabled,
  ...props
}) => {
  const theme: ThemeType = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet,
      darkText,
      isDisabled
    },
    {
      title: customTitleStyle,
      container: customContainerStyle,
      amount: customAmountStyle,
      iconContainer: customIconContainerStyle,
    }
  );
  const { formatCurrencyNumberWithPlusMinus, formatCurrencyNumber } = useTranslation();
  const translatedAmount = typeof amount === 'number' && shouldFormatAsPlusMinus
    ? formatCurrencyNumberWithPlusMinus({ value: amount })
    : amount && formatCurrencyNumber({ value: amount });

  return (
    <Pressable
      {...props}
      style={styles.container}
    >
      <View style={styles.iconTextContainer}>
        {iconName && (
          <View style={styles.iconContainer}>
            <FontIcon
              color={iconColor}
              name={iconName}
              size={iconSize}
            />
          </View>
        )}
        <Title
          style={isDisabled ? styles.disabledTitle : styles.title}
          variant={(isMobile || isTablet) ? 't7' : 't6'}
          weight={isDisabled ? "bold" : "regular"}
        >
          {title}
        </Title>
      </View>
      {(translatedAmount !== '' || !!titleRight) &&
        <View style={styles.rightContainer}>
          {translatedAmount !== '' && !hideAmount && (
            <Title
              style={isDisabled ? styles.disabledAmount : styles.amount}
              variant={isMobile || isTablet ? 't7' : 't6'}
              weight="bold">
              {translatedAmount}
            </Title>
          )}
          {titleRight && (
            <Title
              style={styles.titleRight}
              variant={isMobile || isTablet ? 't7' : 't6'}
              weight="light">
              {titleRight}
            </Title>
          )}
        </View>
      }
    </Pressable>
  );
};

const useStyles = createUseStyles(getStyles);
