import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle, Text, TouchableOpacity } from 'react-native';

import { Title, FontIcon } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface GlobalSavingsCardProps extends GlobalSavingsCardStyles {
  index?: number;
  item?: any;
  plan:any;
  onPlanPress: (planId:string) => void
}

interface GlobalSavingsCardStyles {
  cardRowStyle?: StyleProp<ViewStyle>;
  cardFirstRowStyle?: StyleProp<ViewStyle>;
  labelRowStyle?: StyleProp<ViewStyle>;
  labelDotStyle?: StyleProp<ViewStyle>;
  detailRowStyle?: StyleProp<ViewStyle>;
  detailContentStyle?: StyleProp<ViewStyle>;
  amountStyle?: StyleProp<TextStyle>;
  valueStyle?: StyleProp<TextStyle>;
  chevronStyle?: StyleProp<TextStyle>;
}

type StylesContext = {theme: ThemeType};

export const GlobalSavingsCard: React.FC<React.PropsWithChildren<GlobalSavingsCardProps>> = ({
  index,
  labelRowStyle,
  detailRowStyle,
  cardRowStyle,
  labelDotStyle,
  amountStyle,
  valueStyle,
  chevronStyle,
  plan,
  onPlanPress
}) => {
  const { formatMessage, formatCurrencyNumber } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    {
      labelRowStyle,
      detailRowStyle,
      cardRowStyle,
      labelDotStyle,
      amountStyle,
      valueStyle,
      chevronStyle
    },
  );

  return (
    <TouchableOpacity
      key={index}
      onPress={()=>{onPlanPress(plan.planID);}}
      style={[
        styles.cardRowStyle,
        index === 0 && styles.cardFirstRowStyle
      ]}
    >
      <View style={styles.labelRowStyle}>
        <View style={[styles.labelDotStyle, { backgroundColor: plan.colors[0] }]} />
        <Title
          testId={plan.shortName}
          variant="t7"
          weight="bold">{plan.shortName}</Title>
      </View>
      <View style={styles.detailRowStyle}>
        <View style={styles.detailContentStyle}>
          <Text style={styles.amountStyle}>
            {formatCurrencyNumber({ value: plan.totalAmount })}
          </Text>
          {typeof plan.totalAddedValue === 'number' && (
            <Text
              style={[
                styles.valueStyle,
                { color: plan.totalAddedValue < 0 ?
                  theme.colors.notifications.warning.c500
                  :
                  theme.colors.basics.primary.c500 }
              ]}>
              {formatMessage({
                id: 'Dashboard_CardRepartition_Item_value_label',
                values: { variable: formatCurrencyNumber({ value: plan.totalAddedValue }) }
              })}
            </Text>
          )}
        </View>
        <FontIcon
          name="chevron-droite"
          style={styles.chevronStyle}
        />
      </View>
    </TouchableOpacity>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: GlobalSavingsCardStyles,
): GlobalSavingsCardStyles => ({
  cardRowStyle: [
    {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: context?.theme.metrics.spacing.xm,
      borderTopWidth: 1,
      borderTopColor: context?.theme.colors.basics.white
    },
    style?.cardRowStyle
  ],
  cardFirstRowStyle: [
    {
      borderTopWidth: 0
    },
    style?.cardFirstRowStyle
  ],
  labelRowStyle: [
    {
      width: 120,
      flexDirection: 'row',
      alignItems: 'center'
    },
    style?.labelRowStyle
  ],
  labelDotStyle: [
    {
      width: 13,
      height: 13,
      backgroundColor: context?.theme.colors.basics.white,
      borderRadius: 50,
      marginRight: context?.theme.metrics.spacing.s
    },
    style?.labelDotStyle
  ],
  detailRowStyle: [
    {
      minWidth: 100,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flex: 1,
      alignItems: 'center'
    },
    style?.detailRowStyle
  ],
  detailContentStyle: [
    {
      flexDirection: 'column',
      alignItems: 'flex-end',
      flex: 1
    },
    style?.detailContentStyle
  ],
  amountStyle: [
    {
      fontSize: context?.theme.fonts.fontSize.paragraph.t2,
      fontFamily: context?.theme.fonts.fontFamily.bold,
      textAlign: 'right'
    },
    style?.amountStyle
  ],
  valueStyle: [
    {
      color: context?.theme.colors.basics.primary.c500,
      fontSize: context?.theme.fonts.fontSize.paragraph.t4,
      fontFamily: context?.theme.fonts.fontFamily.bold,
      marginTop: context?.theme.metrics.spacing.xs,
      textAlign: 'right'
    },
    style?.valueStyle
  ],
  chevronStyle: [
    {
      fontSize: context?.theme.metrics.iconSizes.xxm,
      color: context?.theme.colors.text.primary.c500,
      paddingLeft: context?.theme.metrics.spacing.xm,
      fontWeight: 'bold',
      alignSelf: 'center'
    },
    style?.chevronStyle
  ]
});

const useStyles = createUseStyles(getStyles);
