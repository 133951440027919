import React, { useCallback, useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import {
  ArbitrationAmountFieldModalConnected,
  ArbitrationAmountFieldModalConnectedRefType,
  ArbitrationDueDateAmountFieldModalConnected,
  ArbitrationTriggerThresholdModalConnected,
  ArbitrationTriggerThresholdModalConnectedRefType,
  DashboardPageWrapperConnected
} from '@components/ERE360Components'
import { ThresholdCoursesEnum, ThresholdTriggerTypesEnum } from '@constants/arbitrations'
import { RouteNames } from '@constants/navigation'
import { PlanFamillyList } from '@constants/index'
import { IconEnum } from '@ere-uilib/enums'
import {
  CardWithLeftThread,
  ColumnsContainer,
  getPlanTypeColors,
  OperationTotalSubHeader,
  SimpleButton,
  Text,
  useTranslation
} from '@ere-uilib/index'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import { arbitrationBackToQuestionRequest } from '@modules/arbitration/actions/arbitrationActions'
import { ManagmentType, SupportType } from '@modules/common/types'
import { constructStepperItems } from '@pages/Common/utils'
import { RefundsRepartitionValidationModal } from '@pages/Refunds/components'

import { useDeinvestmentSource } from '../ArbitrationReinvestmentPage/hooks/useDeinvestmentSource'
import {
  ArbitrationEcheancePopinModal,
  ArbitrationHeader,
  Management,
  Support
} from '../components'
import { CompartmentCardHeader } from './components'
import { ArbitrationDisinvestmentsSavingProps } from './interfaces'
import { getStyles } from './styles'
import { ArbitrationDueDateAmountFieldModalConnectedRefType } from '@components/ERE360Components/ArbitrationDueDateAmountFieldModalConnected/sharedInterfaces'
import { DueDateProps } from '../components/Support/components/DueDateCard/interface'
import { useController } from './useController'
import { getSelectedSupport } from '@modules/arbitration/selectors'
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";
import {AppNavigatorInterface} from "@navigation/Interfaces";
import { locator } from '@constants/locator'

export const ArbitrationDisinvestmentsSavingPage: React.FC<ArbitrationDisinvestmentsSavingProps> = ({
  onSelectArbitrationDisinvestmentSavingSupport,
  onSelectArbitrationDisinvestmentSavingManagement,
  selectedDispositif,
  selectedCompartment,
  selectedDivestmentSource,
  onSubmitArbitrationDivestment,
  isLoading,
  onClearArbitrationSupportTriggerThreshold
}) => {
    const theme = useTheme()
    const { isMobile, isTablet } = useScreenSizes();
    const { formatMessage, getMessageRaw } = useTranslation();
    const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
  const style = useStyles(
      {
        theme,
        isMobile: isMobile || isTablet,
        isTablet
      },
      {}
    );
    const storeSelectedSupport = useSelector(getSelectedSupport);

    const { sourceCompartment } = useDeinvestmentSource();
    const executionTriggerValue: number | undefined =
      sourceCompartment?.managements?.[0].supports?.[0].formValues?.triggerThreshold?.amount

    const [isValidationModalOpened, setIsValidationModalOpened] = useState(false)

    const triggerThresholdModal = useRef<ArbitrationTriggerThresholdModalConnectedRefType>(null)
    const arbitrationAmountModal = useRef<ArbitrationAmountFieldModalConnectedRefType>(null)
    const arbitrationDueDateAmountModal =
      useRef<ArbitrationDueDateAmountFieldModalConnectedRefType>(null)

    const goToRefundAskProof = () =>
      navigation.navigate(RouteNames.RefundsStack, {
        screen: RouteNames.RefundAskProof
      })

    const stepperItems = constructStepperItems({
      size: 3,
      direction: DirectionEnum.ROW,
      activeStep: 2
    })

    const dispositif = selectedDispositif && selectedDispositif[0]
    const compartment = selectedCompartment
    const totalDesinvestAmount = compartment?.managements.filter(
      management => management.formValues?.isSelected
    )[0]?.formValues?.amount
    const partialReallocation = dispositif?.filterProperties.PartialReallocation
    const isVisibleCompartmentTitle = compartment?.label && compartment.label !== ''
    const isVisibleManagementHeader =
      (compartment && compartment?.managements.length > 1 && partialReallocation) ||
      !partialReallocation
    const compartmentId = compartment?.code || ''
    const planColors = dispositif && getPlanTypeColors(dispositif.name, theme)
    const dispatch = useDispatch()

    const handleBackClick = () => {
      dispatch(arbitrationBackToQuestionRequest(1))
      navigation.goBack()
    }

    const renderBottomActions = () => (
      <View style={style.bottomActionsStyle}>
        <View style={style.bottomActionsButtonsContainerStyle}>
          <SimpleButton
            containerStyle={style.arrowButtonStyle}
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={handleBackClick}
          />
          <SimpleButton
            containerStyle={style.simpleButtonStyle}
            design="solid"
            disabled={!totalDesinvestAmount}
            loading={isLoading}
            onPress={() =>
              onSubmitArbitrationDivestment({
                amount: selectedDivestmentSource?.amount || 0,
                managementId: selectedDivestmentSource?.managementId || '',
                supportIsinCode: selectedDivestmentSource?.supportIsinCode,
                executionTriggerValue: executionTriggerValue
              })
            }
            size="small"
            title={formatMessage({ id: 'Remboursement_boutonValiderContinuer' })}
            testId={locator._arbitration._confirm_and_continue}
          />
        </View>
      </View>
    )

    const RenderHeader = () => {
      return (
        <>
          <ArbitrationHeader
            displayCloseButton
            helpPoint
            helpPointContentHtml={getMessageRaw({
              id: 'Arbitrage_choixDispositifsAide_description'
            })}
            helpPointModalTitle={formatMessage({
              id: 'Arbitrage_choixDispositifsAide_titre'
            })}
            helpPointPosition="left"
            stepperItems={stepperItems}
            title={formatMessage({
              id: 'Arbitrage_etape2'
            })}
          />
          {(isMobile || isTablet) && renderSubHeader()}
        </>
      )
    }

    const renderSubHeader = () => (
      <>
        <OperationTotalSubHeader
          mainAmount={totalDesinvestAmount || 0}
          mainTitle={formatMessage({
            id: 'Arbitrage_desinvest_montantDesinvest'
          })}
        />
      </>
    )

    const handleEditAmountPress = useCallback((management, support, managementId) => {
      arbitrationAmountModal.current?.openWithData({
        displayData: {
          title: dispositif?.name + ' - ' + management?.label || '',
          initialAmount: !support?.formValues?.dueDateOption?.code
            ? support?.formValues?.amount
            : undefined,
          contentTitle: support.supportName?.toUpperCase(),
          amountParameters: {
            arbitrableAmount: support.amount.amount || 0
          },
          allAmountValue: support.amount.amount
        },
        supportIsIn: support.supportIsin,
        compartmentId,
        managementId,
        triggerThreshold: support?.formValues?.triggerThreshold
      })
    }, [])

    const handleTriggerThresholdEditPress = useCallback((support, managementId) => {
      const nextAvailabilityDate = support?.advancedProperties?.ThresholdTriggerValidityDate
      const thresholdTriggertype = support?.advancedProperties?.ThresholdTriggerType
      const thresholdTriggerTitle = support?.advancedProperties?.ThresholdTriggerTitle

      const valueDate = () => {
        switch (thresholdTriggertype) {
          case ThresholdTriggerTypesEnum.VPP:
            return support.netAssetValue?.dateValue
          case ThresholdTriggerTypesEnum.VCP:
            return support?.advancedProperties?.ThresholdTriggerValueDate
          default:
            return ''
        }
      }

      const valueAmount = () => {
        switch (thresholdTriggertype) {
          case ThresholdTriggerTypesEnum.VPP:
            return support?.netAssetValue?.amount
          case ThresholdTriggerTypesEnum.VCP:
            return support?.advancedProperties?.ThresholdTriggerValueAmount
          default:
            return 0
        }
      }

      triggerThresholdModal.current?.openWithData({
        displayData: {
          amount: support.formValues?.triggerThreshold?.amount,
          title: support.supportName || '',
          valuationDate: valueDate() || '',
          triggerPointDate: String(nextAvailabilityDate),
          sharesValue: valueAmount() || 0,
          thresholdTriggerType: thresholdTriggertype || '',
          thresholdTriggerTitle: thresholdTriggerTitle || '',
          course: ThresholdCoursesEnum.arbitration,
          dueDateOption: {
            code: support?.formValues?.dueDateOption?.code,
            date: support?.formValues?.dueDateOption?.date,
            amount: support?.formValues?.dueDateOption?.amount,
          }
        },
        amount: support?.formValues?.amount || support.amount.amount,
        managementId,
        supportIsInCode: support?.supportIsin,
      })
    }, [])

    const handleRenderArbitrationDueDateAmountModal = useCallback(
      (i: DueDateProps, support: SupportType, managementId: string) => {
        const isSelectedEcheance =
          support?.formValues?.dueDateOption?.code === i?.scheduleCode &&
          support?.formValues?.dueDateOption?.date === i.date &&
          support?.formValues?.dueDateOption?.amount === i.amount

        return arbitrationDueDateAmountModal.current?.openWithData({
          displayData: {
            title: `Mon ${dispositif?.name}`, // use label
            subTitle: `${formatMessage({ id: 'MaturityArbitrageFeatureAvailable' })}, ${formatMessage(
              { id: 'MaturityArbitrageFeatureAvailableException' }
            )}`,
            initialAmount: isSelectedEcheance ? support?.formValues?.amount : undefined,
            contentTitle: support.supportName,
            amountParameters: {
              arbitrableAmount: i.amount
            },
            allAmountValue: i.amount,
            dueDateOption: {
              code: i.scheduleCode,
              date: i.date,
              amount: i.amount
            }
          },
          supportIsIn: support.supportIsin,
          compartmentId,
          managementId,
          triggerThreshold: support?.formValues?.triggerThreshold
        })
      },
      [formatMessage]
    )

    const {
      handleHideInfoModalPressed,
      handleShowInfoPopin,
      handleConfirmInfoModalPressed,
      handleSupportSelected,
      handleResetEcheance,
      showInfoPopin
    } = useController({
      handleRenderArbitrationDueDateAmountModal,
      handleEditAmountPress,
      onSelectArbitrationDisinvestmentSavingSupport,
      storeSelectedSupport
    })

    return (
      <DashboardPageWrapperConnected
        cardContentStyle={style.dashboardWrapper}
        renderStickyMobileBottom={() => renderBottomActions()}>
        <ColumnsContainer
          renderHeader={RenderHeader}
          renderRightDesktopColumn={renderSubHeader}>
          <View>
            <Text
              variant="t3"
              weight="light">
              {formatMessage({
                id: 'Arbitrage_etape2_soustitre'
              })}
            </Text>
          </View>
          <View>
            <View style={style.cardContainer}>
              <CardWithLeftThread
                addShadow={true}
                backgroundColor={theme?.colors?.basics?.primary?.c500}
                borderLeftColor={theme?.colors?.basics?.primary?.c500}>
                <>
                  <CompartmentCardHeader
                    backGroundColorCustom={theme?.colors?.basics?.primary?.c500}
                    compartmentAmount={
                      partialReallocation ? compartment?.managements[0].totalAmount : null
                    }
                    compartmentTitle={isVisibleCompartmentTitle ? compartment?.label : null}
                    dispositifTitle={dispositif?.name}
                    key={`compartment${compartmentId}`}
                  />
                  {compartment?.managements.map((management: ManagmentType) => {
                    const managementId = management.id
                    const isManagementSelected = management?.formValues?.isSelected
                    const masterSupportIsinCode = management.supports.filter(
                      support => support.isMaster
                    )[0]?.supportIsin
                    const selectedSupport = management?.supports.find((support)=> support.formValues?.isSelected)
                    const isPartialReallocation = dispositif?.filterProperties.PartialReallocation
                    const isFreeManagement = management.isFree
                    const isDispositifES =  dispositif?.planFamily === PlanFamillyList.ES
                    const isDisplayingExtraManagement = isPartialReallocation && !isFreeManagement && !isDispositifES
                    return (
                      <Management
                        backGroundColorCustom={planColors?.c100}
                        canCompareFunds={management && management.supports.length > 1}
                        cardTitle={formatMessage({
                          id: 'Arbitrage_desinvest_montantDesinvest'
                        })}
                        hasIncorrectDrivingData={
                          management.advancedProperties.HasIncorrectDrivingData
                        }
                        isDisplayingExtraManagement={isDisplayingExtraManagement}
                        isManagementSelectable={!isPartialReallocation}
                        isVisibleManagementHeader={isVisibleManagementHeader}
                        linkUnderfieldLabel={formatMessage({
                          id: 'Arbitrage_modaleDesinvest_Toutdesinvest'
                        })}
                        management={management}
                        onEditMasterAmountPress={() => {
                          arbitrationAmountModal.current?.openWithData({
                            displayData: {
                              title: dispositif?.name + ' - ' + management.label || '',
                              initialAmount: management.formValues?.amount || undefined,
                              amountParameters: {
                                arbitrableAmount: management.totalAmount || 0
                              },
                              allAmountValue: management.totalAmount
                            },
                            supportIsIn: masterSupportIsinCode,
                            compartmentId,
                            managementId
                          })
                        }}
                        onLinkUnderFieldPress={() =>
                          onSelectArbitrationDisinvestmentSavingSupport({
                            selectState: true,
                            compartmentId,
                            managementId: managementId,
                            supportIsin: masterSupportIsinCode,
                            amount: management.totalAmount,
                            triggerThreshold: selectedSupport?.formValues?.triggerThreshold,
                          })
                        }
                        onSelectChange={() => {
                          !isManagementSelected &&
                            onSelectArbitrationDisinvestmentSavingManagement({
                              selectState: !isManagementSelected,
                              compartmentId,
                              managementId: managementId,
                              amount: management.totalAmount
                            })
                        }}
                        onSelectSupportMasterChange={() => {
                          !isManagementSelected &&
                            onSelectArbitrationDisinvestmentSavingSupport({
                              selectState: !isManagementSelected,
                              compartmentId,
                              managementId: managementId,
                              supportIsin: masterSupportIsinCode,
                              amount: management.totalAmount
                            })
                        }}>
                        <View style={style.savingContainer}>
                          {management.supports.map((support: SupportType) => {
                          const isSupportSelectable = (management.isFree || isDispositifES) && partialReallocation
                          const isSupportDisabled =
                            !support.filterProperties?.StockReallocation || (!isFreeManagement && !isDispositifES)
                            const isSupportSelected =
                              isSupportSelectable &&
                              storeSelectedSupport &&
                              support.formValues?.isSelected
                            const linkUnderfieldLabel = formatMessage({
                              id: 'Arbitrage_modaleDesinvest_Toutdesinvest'
                            })

                            const supportAmount = support.amount.amount
                            const _handleTriggerThresholdEditPress = () =>
                              handleTriggerThresholdEditPress(support, managementId)
                            return (
                              <Support
                                isDisabledSupport={isSupportDisabled}
                                isInputEditable={isSupportSelected}
                                isSupportSelectable={isSupportSelectable}
                                isSupportSelected={isSupportSelected}
                                IsTriggerThreshold={
                                  !!support?.advancedProperties?.HasThresholdTrigger
                                }
                                linkUnderfieldLabel={linkUnderfieldLabel}
                                onEditAmountPress={() => handleShowInfoPopin(support, management)}
                              onLinkUnderFieldPress={() => {
                                {
                                  onSelectArbitrationDisinvestmentSavingSupport({
                                    amount: support.amount.amount,
                                    compartmentId,
                                    managementId,
                                    supportIsin: support.supportIsin,
                                    selectState: isSupportSelected,
                                      triggerThreshold: support?.formValues?.triggerThreshold,
                                    })
                                    handleResetEcheance()
                                  }
                                }}
                                onLinkUnderFieldEchenacePress={(echeance: DueDateProps) => {
                                  onSelectArbitrationDisinvestmentSavingSupport({
                                    amount: echeance.amount,
                                    compartmentId,
                                    managementId,
                                    supportIsin: support.supportIsin,
                                    selectState: isSupportSelected,
                                    triggerThreshold: support?.formValues?.triggerThreshold,
                                    dueDateOption: {
                                      code: echeance?.scheduleCode,
                                      date: echeance?.date,
                                      amount: echeance.amount
                                    }
                                  })
                                }}
                                onSelectChange={() =>
                                  !isSupportSelected && handleSupportSelected(support, management)
                                }
                                onTriggerThresholdDelete={() => {
                                  onClearArbitrationSupportTriggerThreshold({
                                    selectState: isSupportSelected,
                                    amount: support?.formValues?.amount || support.amount.amount,
                                    managementId,
                                    supportIsin: support.supportIsin,
                                    dueDateOption: {
                                      code: support?.formValues?.dueDateOption?.code,
                                      date: support?.formValues?.dueDateOption?.date,
                                      amount: support?.formValues?.dueDateOption?.amount,
                                    }

                                  })
                                }}
                                onTriggerThresholdEditPress={_handleTriggerThresholdEditPress}
                                ontriggerDueDateEditPress={(i: DueDateProps) =>
                                  handleShowInfoPopin(support, management, i)
                                }
                                shouldDisplayAddedValue={dispositif?.planFamily != PlanFamillyList.RC}
                                shouldDisplayRiskLevel={true}
                                support={support}
                                supportAmount={supportAmount}
                                checkboxTestId={`check_box_${support.supportName}`}
                                supportIndexTestId={`${support.supportName}`}
                              />
                            )
                          })}
                        </View>
                      </Management>
                    )
                  })}
                </>
              </CardWithLeftThread>
            </View>

            <RefundsRepartitionValidationModal
              onClose={() => {
                setIsValidationModalOpened(false)
              }}
              onSubmit={() => {
                goToRefundAskProof()
                setIsValidationModalOpened(false)
              }}
              visible={isValidationModalOpened}
            />

            <ArbitrationAmountFieldModalConnected ref={arbitrationAmountModal} />
            <ArbitrationTriggerThresholdModalConnected ref={triggerThresholdModal} />
            <ArbitrationDueDateAmountFieldModalConnected ref={arbitrationDueDateAmountModal} />
            <ArbitrationEcheancePopinModal
              isModalVisible={showInfoPopin}
              onCloseModal={() => handleHideInfoModalPressed()}
              onConfirmButtonPress={() => handleConfirmInfoModalPressed()}
            />
          </View>
          {!isMobile && !isTablet && renderBottomActions()}
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
    )
  }
const useStyles = createUseStyles(getStyles)
