import axios from 'axios';
import dayjs from 'dayjs';
import { Platform } from 'react-native';
import {
  call, put, select,
  take,
  takeLatest
} from 'redux-saga/effects';

import { CLIENT_SECRET } from '@config/index';
import { RouteNames } from '@constants/navigation';
import { catchApiExceptions } from '@modules/apiAuthorization';
import * as pendingTasksActions from '@modules/pending-tasks/actions/pendingTasksActions';
import {
  callback,
  fetchUserInfo,
  loginOpenId,
  logoutOpenId,
  signInNativeAppProcess,
  silentRenewTokenOpenId
} from './services';
import { getAuthConfig, getAuthSilentRenewConfig } from '@modules/settings/selectors';
import { AuthConfigType } from '@modules/settings/types';
import { RootNavigation } from '@navigation/RootNavigation';

import { runManager } from '../moduleManager';
import { getCredentialsFromKeystore, isSensorAvailable, setCredentialsIntoKeystore, simplePrompt } from '../utils/biometricsMethods';
import * as actionAuth from './actions/authActions';
import {
  AuthActionType,
  SigninNativeAppRequestAction,
  SilentRenewTokenRequest
} from './actions/authActionsTypes';
import { getBiometricUserAcceptionStatus, getUser } from './selectors';
import {
  AuthenticationClaims,
  BimetricCredentialsType,
  SensorAvailabilityResult,
  SimplePromptResult,
  UserMobileAppAdditionalClaimsState,
  UserState
} from './types';

export function* signInNativeAppUsingBiometric() {
  // biometrics check
  const biometricCredentials: BimetricCredentialsType = yield select(getBiometricUserAcceptionStatus)
  const credentials: {
    username: string,
    password: string
  } = yield call(getCredentialsFromKeystore)
  const { available }: SensorAvailabilityResult = yield isSensorAvailable()
  if (!available || !biometricCredentials.biometricAcceptationStatus) return
  if (available && biometricCredentials.biometricAcceptationStatus) {
    const { success }: SimplePromptResult = yield simplePrompt()
    if (success) {
      const user: UserState = yield manageNativeAppSignin({
        type: AuthActionType.SIGNIN_NATIVE_APP_REQUEST,
        userCredentials: {
          userName: credentials.username,
          password: credentials.password
        },
        memorizeLogin: true
      })
      yield axios.defaults.headers.common.Pragma = "no-cache";
      yield axios.defaults.headers.common["Cache-Control"] = "no-cache";
      yield axios.defaults.headers.common.Authorization = 'Bearer ' + user?.access_token;
      yield put(actionAuth.signInNativeAppUsingBiometricSuccess(user))
      yield RootNavigation.replace(RouteNames.OidcCallbackRedirect)
      yield put(pendingTasksActions.getPendingTasksRequest());
    } else {
      yield put(actionAuth.signInNativeAppUsingBiometricFailure({
        error: '',
        error_description: ''
      }))
    }
  }
}
function* manageNativeAppSignin(action: SigninNativeAppRequestAction): any {
  yield put(actionAuth.authInitError());
  const authConfig: AuthConfigType = yield select(getAuthConfig)

  const claims: AuthenticationClaims = {
    client_id: authConfig.client_id,
    client_secret: CLIENT_SECRET,
    scope: authConfig.scope,
    userName: action.userCredentials.userName,
    password: action.userCredentials.password,
    grant_type: 'password',
    authority: authConfig.authority
  };
  const response: {
    data: UserMobileAppAdditionalClaimsState
  } = yield call(signInNativeAppProcess, claims)
  const result = response.data
  //const user: UserState
  const user: UserState = {
    access_token: result.access_token,
    expires_at: Number(dayjs().add(result.expires_in?? 0, 'second').unix()),
    refresh_token: result.refresh_token,
    scope: result.scope,
    token_type: result.token_type,
  }
  if (Platform.OS !== 'web') {
    const userInfoResponse = yield call(fetchUserInfo, claims.authority ?? '', user?.access_token);
    const { family_name, given_name} = userInfoResponse.data
    const lastconnection = "https://login.ere.bnpparibas/claims/lastconnection"
    yield put(actionAuth.getUserInfoSuccess({
      familyName: family_name,
      givenName: given_name,
      [lastconnection]: userInfoResponse.data[lastconnection]
    }));
    return user
  }
}

export function* signInNativeApp(action: SigninNativeAppRequestAction) {
  const user: UserState = yield manageNativeAppSignin(action)
  yield put(actionAuth.signInNativeAppSuccess(user));
  // biometrics init
  const biometricCredentials: BimetricCredentialsType = yield select(getBiometricUserAcceptionStatus)
  const { available, biometryType }: SensorAvailabilityResult = yield isSensorAvailable() // todo: check also biometrics type
  const shouldAskUserForBiometricAuth = biometricCredentials.biometricAcceptationStatus === undefined ||
    (available && !biometricCredentials.biometricAcceptationStatus)
  if (shouldAskUserForBiometricAuth) {
    yield put(actionAuth.promptBiometricUserModalAskRequest(true, biometryType || 'FaceID'))
    // waiting for user to accept/cancel biometric on modal asking
    yield take([
      actionAuth.promptBiometricUserModalAskSuccess,
    ]);
    const biometricCredentials: BimetricCredentialsType = yield select(getBiometricUserAcceptionStatus) // get new values
    const status = biometricCredentials.biometricAcceptationStatus
    if (!status) {
      yield put(actionAuth.saveBiometricCredentials(status))
    } else {
      yield setCredentialsIntoKeystore(action.userCredentials.userName || '', action.userCredentials.password || '');
      yield put(actionAuth.saveBiometricCredentials(status))
    }
  }
  // when user change his login/password from web and the biometric auth failed, we have to store his new login/password when he set them again in form
  if (available && biometricCredentials.biometricAcceptationStatus) yield setCredentialsIntoKeystore(action.userCredentials.userName || '', action.userCredentials.password || '');
  yield RootNavigation.replace(RouteNames.OidcCallbackRedirect)
  if (action.memorizeLogin) yield put(actionAuth.saveLogin(action.userCredentials.userName))
  axios.defaults.headers.common.Pragma = "no-cache";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";
  axios.defaults.headers.common.Authorization =
    'Bearer ' + user?.access_token;
  yield put(pendingTasksActions.getPendingTasksRequest());
}

export function* signinRedirectCallback(
): any {
  yield put(actionAuth.authInitError());

  const user: UserState = yield select(getUser);
  const sessionTimeStamp = user?.expires_at;
  const isUserAlreadyLoggedIn = sessionTimeStamp &&
  dayjs(sessionTimeStamp * 1000).format() > dayjs().format();

  const authConfig = yield select(getAuthConfig);

  /* manage case when user lose oidc context stored in local storage,
    so when redirecting from idp a comparison is made between state url
    params and localStorage key na e (oidc.{state}) if exist.
    of oidc context is lost we call loginOpenId again to have new context and to preserve from error page */

  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  const isOidcContextExist = localStorage.getItem(`oidc.${state}`) !== null;

  // if we don't get the oidc context (probably user used old saved idp url to login)
  if (!isOidcContextExist) {
    // use loginOpenId to recreate oidc context the call back will bring user back with good context
    yield call(loginOpenId, authConfig);
  } else {
    // get user info
    const userInfos: UserState = yield call(callback, authConfig);
    // if successfully got userInfos
    if (userInfos) {
      yield put(actionAuth.signinRedirectCallbackSuccess(userInfos));
      // set axios header config (Security + Authorization)
      axios.defaults.headers.common.Pragma = "no-cache";
      axios.defaults.headers.common["Cache-Control"] = "no-cache";
      axios.defaults.headers.common.Authorization =
        'Bearer ' + userInfos?.access_token;

      yield put(pendingTasksActions.getPendingTasksRequest());
      if (Platform.OS !== 'web') {
        const userInfoResponse = yield call(fetchUserInfo, authConfig.authority ?? '', userInfos?.access_token);
        const { family_name, given_name } = userInfoResponse.data
        const lastconnection = "https://login.ere.bnpparibas/claims/lastconnection"
        yield put(actionAuth.getUserInfoSuccess({
          familyName: family_name,
          givenName: given_name,
          [lastconnection]: userInfoResponse.data[lastconnection]
        }));
      }
      // if not getting userInfos but already got valid infos we try to use them instead
    } else if (isUserAlreadyLoggedIn) {
      axios.defaults.headers.common.Pragma = "no-cache";
      axios.defaults.headers.common["Cache-Control"] = "no-cache";
      axios.defaults.headers.common.Authorization =
        'Bearer ' + user?.access_token;
      yield put(pendingTasksActions.getPendingTasksRequest());

      // if not getting userInfos and got nothing to fallback, we send error action
    } else {
      yield catchApiExceptions(AuthActionType.SIGNIN_REDIRECT_CALLBACK_FAILURE, {});
    }
  }
}

// LOGIN ERROR AND LOGOUT

export function* authLogoutCallback(): any {

  const authConfig = yield select(getAuthConfig);
  const user = yield select(getUser);
  const expiresAt = user?.expires_at || 0;
  const sessionExpireAt = dayjs((expiresAt * 1000)).format();
  const currentTime = dayjs().format();
  const isSessionExpired = currentTime >= sessionExpireAt;
  delete axios.defaults.headers.common.Authorization;

  yield put(actionAuth.logoutCallbackSuccess());

  if (isSessionExpired) { // note: pourquoi cette diff de comportement selon si on vient d'une déco de session auto ou bien mannuelement => à voir s'il faut pas se déco de l'idp dans tout les cas
    yield RootNavigation.navigate(RouteNames.OidcLogoutCallbackRedirect, null);
  } else {
    yield put(actionAuth.authInitError());
    yield call(logoutOpenId, authConfig, user.id_token);
  }
}

/*export function* authLogoutMobileAppCallback(): any {
  const authConfig = yield select(getAuthConfig);
  const user: UserState = yield select(getUser);
  delete axios.defaults.headers.common.Authorization;
  yield call(logoutMobileApp, authConfig, user?.id_token)
  yield put(actionAuth.logoutCallbackSuccess());
  yield RootNavigation.navigate(RouteNames.OidcLogoutCallbackRedirect);

}*/

// resfresh token

function* silentRenewToken(
  action: SilentRenewTokenRequest): any {

  let newUser: UserState

  const user: UserState = yield select(getUser)
  const authRenewConfig = yield select(getAuthSilentRenewConfig);
  if (Platform.OS !== 'web') {
    const claims: AuthenticationClaims = {
      client_id: authRenewConfig.client_id,
      client_secret: CLIENT_SECRET,
      scope: authRenewConfig.scope,
      grant_type: 'refresh_token',
      refresh_token: user.refresh_token,
      authority: authRenewConfig.authority
    };
    const response = yield call(signInNativeAppProcess, claims);

    const result = response.data
    newUser = {
      access_token: result.access_token,
      expires_at: Number(dayjs().add(result.expires_in, 'second').unix()),
      refresh_token: result.refresh_token,
      scope: result.scope,
      token_type: result.token_type,
    }
  } else {
    const response = yield call(silentRenewTokenOpenId, authRenewConfig);
    newUser = response
  }
  yield axios.defaults.headers.common.Pragma = "no-cache";
  yield axios.defaults.headers.common["Cache-Control"] = "no-cache";
  yield axios.defaults.headers.common.Authorization =
    'Bearer ' +
    newUser.access_token;
  yield put(actionAuth.silentRenewTokenSuccess(newUser));
  if (action?.outputAction?.outputActionType) {
    const outputAction = {
      type: action?.outputAction?.outputActionType,
      ...(action?.outputAction?.outputActionParams || undefined),
    };
    return yield put(outputAction);
  }
}

export function* authLoginSagas() {
  yield takeLatest(
    AuthActionType.SIGNIN_REDIRECT_CALLBACK_REQUEST,
    runManager(signinRedirectCallback, AuthActionType.SIGNIN_REDIRECT_CALLBACK_FAILURE),
  );
  // LOGOUT AND ERROR
  yield takeLatest(
    AuthActionType.LOGOUT_CALLBACK_REQUEST,
    runManager(authLogoutCallback, AuthActionType.LOGOUT_CALLBACK_FAILURE)
  );
  // refresh session
  yield takeLatest(
    AuthActionType.SILENT_RENEW_TOKEN_REQUEST,
    runManager(silentRenewToken, AuthActionType.SILENT_RENEW_TOKEN_FAILURE)
  );
  yield takeLatest(
    AuthActionType.SIGNIN_NATIVE_APP_REQUEST,
    runManager(signInNativeApp, AuthActionType.SIGNIN_NATIVE_APP_FAILURE)
  );
  yield takeLatest(
    AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_REQUEST,
    runManager(signInNativeAppUsingBiometric, AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_FAILURE)
  );
}
