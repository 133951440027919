import { StackNavigationProp } from '@react-navigation/stack'
import React, { useMemo, useState } from 'react'
import { BulletList } from 'react-content-loader/native'
import { useWindowDimensions, View } from 'react-native'
import { useSelector } from 'react-redux'

import { RouteNames } from '@constants/navigation'
import { HelpPoint, IconButton, InnerPageContainer, SavingsPlaceholder } from '@ere-uilib/index'
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { RepartitionFiltersState, RepartitionsState } from '@ere-uilib/types/index'
import { getSelectedCompany } from '@modules/dashboard/selectors'
import { AppState } from '@modules/reducers'
import { getRepartitionsFilters } from '@modules/savings/selectors'
import { filterRepartitions } from '@modules/savings/utils'
import { RenderGroupedRepartitionsConnected } from './RenderGroupedRepartitionsConnected'
import { RepartitionsFilterModal } from './RepartitionsFilterModal'
import { getStyles } from './styles'
import { SavingRepartitionCard } from '../SavingRepartitionCard'
import {useNavigation} from "@react-navigation/native";

interface SavingsRepartitionsProps {
  repartitions: any
  amount?: number
  selectedPlan: number
  isApiLoading: boolean
  planId?: string
  planName: string
  onSetRepartitionFilters: (repartitionFilters: RepartitionFiltersState) => void
}

export const SavingsRepartitionsConnected = ({
  repartitions,
  amount,
  selectedPlan,
  planId,
  planName,
  isApiLoading,
  onSetRepartitionFilters
}: SavingsRepartitionsProps) => {
  const newTheme = useTheme()
  const repartitionFilters = useSelector((state: AppState) => getRepartitionsFilters(state))
  const selectedCompany = useSelector((state: AppState) => getSelectedCompany(state));
  const filteredRepartition = filterRepartitions(repartitions, repartitionFilters)
  const { formatMessage, getMessageRaw } = useTranslation()
  const isMobile = useScreenSizes().isMobile
  const isTablet = useScreenSizes().isTablet
  const [displayFilterModal, setDisplayFilterModal] = useState(false)
  const theme = useTheme();
  const navigation = useNavigation();
  const styles = useStyles({ theme, isMobile: isMobile || isTablet }, {})
  const onRepartitionCardPress = (fundId: string, title: string) => {
    fundId && navigation.navigate(RouteNames.Funds, { id: fundId, title: title })
  }
  const { width, height } = useWindowDimensions()
  const bulletListHeight = useMemo(() => {
    return height / 4
  }, [])

  const renderRepartitionList = () => {
    const isRepartitionHaveGroupFilters = repartitionFilters?.groupFilter?.length > 0
    if (isRepartitionHaveGroupFilters) {
      return (
        <RenderGroupedRepartitionsConnected
          onRepartitionCardPress={onRepartitionCardPress}
          planId={planId}
          repartitionFilters={repartitionFilters}
          repartitions={repartitions}
        />
      )
    } else {
      return filteredRepartition?.map((repartition: RepartitionsState, index: number) => {
        return (
          <SavingRepartitionCard
            addedValue={repartition.amount?.addedValue}
            amount={repartition.amount?.amount}
            hasNoFundSheet={repartition?.hasNoFundSheet}
            isRiskLevelSRI={!!repartition.riskLevelSRI}
            key={index + repartition.supportName}
            legalStatus={repartition.legalStatus}
            onCardPress={() =>
              onRepartitionCardPress(repartition.supportIsin, repartition.supportName)
            }
            performanceValue={repartition.performanceValue}
            riskLevel={repartition.riskLevelSRI || repartition.riskLevelSRRI}
            shareCount={selectedCompany?.isES ? repartition.amount.numberOfUnits : 0}
            shouldDisplayAddedValue={!!selectedCompany?.isES }
            source={repartition?.source}
            supportName={repartition.supportName}
            data-testid={`item_card:${index}`}
          />

        )
      })
    }
  }

  return (
    <InnerPageContainer>
      <RepartitionsFilterModal
        isVisible={displayFilterModal}
        onApplyFilter={() => {}}
        onApplyFiltersToReduxState={onSetRepartitionFilters}
        onClose={() => {
          setDisplayFilterModal(false)
        }}
        repartitionFilters={repartitionFilters}
      />
      <View style={styles.buttonContainer}>
        <IconButton
          buttonInformations={{
            icon: 'filtres',
            title: `${formatMessage({ id: 'Saving_Tab4_Filter_button' })}`
          }}
          iconButtonStyle={styles.iconButton}
          iconButtonTitleStyle={styles.iconButtonText}
          iconColor={theme.colors.basics.white}
          index={1}
          onIconButtonPressed={() => setDisplayFilterModal(true)}
          titleVariant="t3"
        />
        <HelpPoint
          contentHtml={getMessageRaw({
            id: `saving_Tab1_info_content_${planName}`,
            defaultMessage: ' '
          })}
          iconSize={newTheme.metrics.iconSizes.l}
          modalTitle={formatMessage({ id: 'fund-card_help-info_menu' })}
          style={styles.helpPointStyle}
        />
      </View>
      {isApiLoading ? (
        <View>
          <BulletList
            height={bulletListHeight}
            style={{ flex: 1 }}
            width={'100%'}
          />
        </View>
      ) : amount === 0 ? (
        <SavingsPlaceholder
          titleLabelId={
            selectedPlan === 0 ? 'Saving_Tab1_noFundsAll_label' : 'Saving_Tab1_noFunds_label'
          }
        />
      ) : (
        renderRepartitionList()
      )}
    </InnerPageContainer>
  )
}

const useStyles = createUseStyles(getStyles)
