import {
  ActivableButtonSet,
  AdvantageCard,
  BankAccountInfosCard,
  BubbleContainer,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  InstallmentRepartitionConsentModal,
  InstallmentsStepper, NavigationHeader, OperationTotalSubHeader, SimpleButton,
  Title
} from '@components/index';
import { PaymentModeList } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import {
  DirectionEnum,
  StepperSizeEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { PaymentModeDataState } from '@modules/installment/types';
import { constructStepperItems } from '@pages/Common/utils';
import React, { useState } from 'react';
import { View } from 'react-native';
import { InstallmentPaymentModePageProps as Props } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';


export const InstallmentsPaymentModePageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
  installmentRepartitionData,
  onSetInstallmentPaymentModeChoice,
  installmentPaymentMode,
  installmentPaymentModeData,
  installmentDecisionAllResponses,
  calculatedContributionError,
  isEditMode
}) => {
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const { formatMessage, formatCurrencyNumber, getMessageRaw } = useTranslation();
  const [consentModalVisible, setConsentModalVisible] = useState(false);
  const [paymentModeSelected, setPaymentModeSelected] = useState<string | undefined>(undefined);
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();

  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});
  const isMultiplePaymentMode = installmentPaymentModeData?.length > 1;

  const stepperItems = constructStepperItems({
    size: isEditMode ? 2 : 4,
    direction: DirectionEnum.ROW,
    activeStep: isEditMode ? 2 : 3
  });

  const paymentModeData = [
    {
      label: formatMessage({ id: 'VV_paymentmode_cb_button' }),
      value: PaymentModeList.cb
    },
    {
      label: formatMessage({ id: 'VV_paymentmode_bankaccount_button' }),
      value: PaymentModeList.debit
    }
  ];

  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }

  // renders

  const renderHeader = () => (
    <>
      <NavigationHeader
        containerStyle={{
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.basics.grey.c200
        }}
        displayCloseButton
        headerLine={{ borderBottomWidth: 0 }}
        helpPoint
        helpPointContentHtml={getMessageRaw({
          id: 'VV_paymentmode_info_content'
        })}
        helpPointModalTitle={formatMessage({ id: 'VV_paymentmode_info_title' })}
        onClose={() =>
          navigation.navigate(RouteNames.InstallmentStack, {
            screen: RouteNames.InstallmentModal,
            params: {
              clearAction: InstallmentActionsType.CLEAR_INSTALLMENT_DATA
            }
          })
        }
        title={formatMessage({ id: 'VV_paymentmode_title' })}>
        <View style={styles.installmentsStepperContainerStyle}>
          <InstallmentsStepper
            containerStyle={styles.stepperStyle}
            stepperItems={stepperItems}
            stepperSize={isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
          />
        </View>
      </NavigationHeader>
    </>
  );
  const isContributionPositive =
    !!installmentRepartitionData?.formValues?.totalContribution &&
    installmentRepartitionData?.formValues?.totalContribution > 0;
  const renderSubHeader = ({ addShadow }: { addShadow: boolean }) =>
    installmentRepartitionData && (
      <>
        <OperationTotalSubHeader
          addShadow={addShadow}
          contributionAmount={
            installmentRepartitionData.formValues?.totalContribution
          }
          contributionTitle={formatMessage({
            id: 'VV_repartition_total_contribution_label'
          })}
          mainAmount={installmentRepartitionData.formValues?.totalAmount}
          mainTitle={formatMessage({
            id: 'VV_repartition_total_amount_label'
          })}
        />
        <View style={styles.advantageCardsContainer}>
          {installmentDecisionAllResponses?.TaxOut && (
            <AdvantageCard
              advantageAmount={formatCurrencyNumber({
                value: installmentRepartitionData.formValues?.totalAmount || 0
              })}
              advantageDescription={formatMessage({
                id: 'VV_recap_taxation_label'
              })}
              advantageTitle={formatMessage({ id: 'VV_recap_taxation_title' })}
              containerStyle={styles.advantageCard}
            />
          )}
          {installmentRepartitionData?.advancedProperties?.HasContribution && isContributionPositive && (
            <AdvantageCard
              advantageAmount={formatCurrencyNumber({
                value: installmentRepartitionData.formValues?.totalContribution || 0
              })}
              advantageDescription={formatMessage({
                id: 'VV_recap_contribution_label'
              })}
              advantageTitle={formatMessage({
                id: 'VV_recap_contribution_title'
              })}
              containerStyle={styles.advantageCard}
              display_netText
              hasContributionError={!!calculatedContributionError?.code}
            />
          )}
        </View>
      </>
    );

  const renderRightDesktopColumn = () =>
    installmentRepartitionData && (
      <>
        <BubbleContainer containerStyle={styles.subHeaderContainerStyle}>
          {renderSubHeader({ addShadow: false })}
        </BubbleContainer>
      </>
    );

  const renderBottomActions = () => (
    <View style={styles.bottomActionsStyle}>
      <SimpleButton
        containerStyle={styles.arrowButtonStyle}
        design="outlined"
        leftIcon={IconEnum.CHEVRON_LEFT}
        onPress={() => {
          navigation.goBack();
        }}
        title={!isMobile ? formatMessage({ id: 'VV_repartition_back_button' }) : undefined}
      />
      <SimpleButton
        containerStyle={styles.simpleButtonStyle}
        disabled={!installmentPaymentMode}
        onPress={() => {
          setConsentModalVisible(true);
        }}
        title={formatMessage({ id: 'VV_paymentmode_validate_button' })}
        data-testid={locator._payment._confirm_my_payment}
      />
      {installmentPaymentMode && (
        <InstallmentRepartitionConsentModal
          modalVisible={consentModalVisible}
          onClose={() => {
            setConsentModalVisible(false);
          }}
          onValid={() => {
            setConsentModalVisible(false);
            navigation.navigate(RouteNames.InstallmentStack, {
              screen: RouteNames.InstallmentsSynthesis
            });
          }}
          paymentMethod={installmentPaymentMode}
          planFamily={installmentRepartitionData?.planFamily}
          testIdAccept={locator._payment._accept_consent}
        />
      )}
    </View>
  );

  const renderMainContent = () => {
    const bankAccountDetail = installmentPaymentModeData?.find(
      (paymentItem: PaymentModeDataState) => paymentItem.paymentMethod === installmentPaymentMode
    )?.bankAccountDetail;

    return (
      <View>
        <Title
          style={styles.titleStyle}
          variant={'t6'}
          weight="bold">
          {formatMessage({
            id: !isMultiplePaymentMode ? 'VV_paymentmode_bankaccount_title' : 'VV_paymentmode_title'
          })}
        </Title>

        {isMultiplePaymentMode && (
          <View style={styles.paymentModeContainer}>
            {paymentModeData.map((paymentModeItem, i) => {
              return (
                <View style={styles.ActivableButtonStyle} key={`paymentitem_${i}`}>
                  <ActivableButtonSet
                    isClassicStyle
                    isSelected={paymentModeSelected === paymentModeItem.value}
                    key={i}
                    label={paymentModeItem.label}
                    onChange={() => {
                      setPaymentModeSelected(paymentModeItem.value);
                      onSetInstallmentPaymentModeChoice(paymentModeItem.value);
                    }}
                    data-testid={paymentModeItem.label}
                    hovered={i === hoveredIndex ? hovered : false}
                    setHovered={(hoveredValue) => handleHovered(hoveredValue, i)}
                  />
                </View>
              );
            })}
          </View>
        )}

        {installmentPaymentMode === PaymentModeList.debit &&
          bankAccountDetail && (
            <BankAccountInfosCard
              AccountInfoData={{
                ...bankAccountDetail,
                domiciliation: bankAccountDetail?.domiciliation || undefined,
                titulaire: bankAccountDetail?.titulaire || undefined
              }}
              containerStyle={styles.bankAccountInfosCardContainer}
            />
          )}

        {!(isMobile || isTablet) && renderBottomActions()}
      </View>
    );
  };

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={() => renderBottomActions()}>
      <View style={styles.containerStyle}>
        <ColumnsContainer
          renderHeader={() => renderHeader()}
          renderRightDesktopColumn={() => renderRightDesktopColumn()}>
          {renderMainContent()}
        </ColumnsContainer>
      </View>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);

export default InstallmentsPaymentModePageComponent;
