import { sumBy } from 'lodash';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { VictoryLabel, VictoryPie } from 'victory-native';

import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { DashboardRepartitionPlanType } from '@ere-uilib/types';

interface Props extends RepartitionDonutStyles {
  allPlans: DashboardRepartitionPlanType[];
}

type RepartitionDonutStyles = {
  containerStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {theme: ThemeType};

interface RepartitionDonutItemProps {
  x: string
  y: number
  dy?: number
  label?: string
  textAnchor?: string
}

export const RepartitionDonut: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  allPlans,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle });
  const labelFontSize = 14;
  const createNewDataArray = () => {
    const total = sumBy(allPlans, 'value');
    let currentTotal = 0;
    let lastY = 0;
    let lastDy = 0;

    const newData:RepartitionDonutItemProps[] = allPlans.map((plan, index)=>{
      const y = (plan.value * 100) / total;
      const dy = y <= 10 && lastY <= 10 ? lastDy - (labelFontSize - 1) : 5;
      const label = `${plan.label} : ${Math.round(y)}%`;
      const textAnchor = currentTotal + y / 2 < 50 ? 'start' : 'end';

      currentTotal = currentTotal + y;
      lastY = y;
      lastDy = dy;

      return {
        x: plan.label,
        y,
        dy,
        label,
        textAnchor,
      };
    });

    return newData;
  };

  const parsedData = createNewDataArray();
  return parsedData?.length > 0 ? (
    <View style={styles.containerStyle}>
      <VictoryPie
        colorScale={allPlans.map(plan=>plan.color)}
        data={parsedData}
        height={300}
        innerRadius={65} // BY default height:400
        labelComponent={
          <VictoryLabel
            dy={({ datum }) => datum.dy}
            //@ts-ignore textAnchor type giving issues
            textAnchor={({ datum }) => datum.textAnchor}
          />
        }
        labelPlacement={'vertical'}
        labels={({ datum }) => datum.label}
        padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
        radius={100}
        style={{
          data: {
            stroke: theme.colors.basics.white,
            strokeWidth: 1,
          },
          labels: {
            padding: 10,
            margin: 10,
            fontSize: labelFontSize,
            fontFamily: theme.fonts.fontFamily.bold,
          },
        }}
        width={400}
      />
    </View>
  ) : null;
};

const getStyles = (
  context?: StylesContext,
  style?: RepartitionDonutStyles,
): RepartitionDonutStyles => ({
  containerStyle: [
    {
      display: 'flex',
      position: 'relative',
      margin: context?.theme.metrics.spacing.s,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    style?.containerStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
