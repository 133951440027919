import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

import { RouteNames } from '@constants/navigation';
import { FontIcon, Text } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { Table, TableCellInterface, TableCellTypeEnum } from '@ere-uilib/molecules';
import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { AppNavigatorInterface } from '@navigation/Interfaces';

export type BudgetInsightDatatableType = {
  amount: number;
  color?: string;
  establishment: string;
  account: string;
};

export type BudgetInsightHeaderTableType = {
  balance: number;
};

type BudgetInsightAccountsListCardContentProps = {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  contributionData?: {
    legend: string;
    head: BudgetInsightHeaderTableType;
    body?: BudgetInsightDatatableType[];
  };
};

type ContextType = {
  theme: ThemeType;
  isWeb: boolean;
};
type StyleType = {
  button?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  leftCard?: StyleProp<ViewStyle>;
  ContainerStyle?: StyleProp<ViewStyle>;
  entrieColStyle?: StyleProp<ViewStyle>;
  entrieColContainerStyle?: StyleProp<ViewStyle>;
  entrieColPointStyle?: StyleProp<ViewStyle>;
  entrieColTextStyle?: StyleProp<TextStyle>;
  colStyle?: StyleProp<ViewStyle>;
  lastColStyle?: StyleProp<ViewStyle>;
  headerTextStyle?: StyleProp<TextStyle>;

  mainContainer?: StyleProp<ViewStyle>;
  tableContainer?: StyleProp<ViewStyle>;
  tableHead?: StyleProp<ViewStyle>;
  tableHeadStart?: StyleProp<ViewStyle>;
  tableHeadEnd?: StyleProp<ViewStyle>;
  tableLastRowStart?: StyleProp<ViewStyle>;
  tableLastRowEnd?: StyleProp<ViewStyle>;
  legend?: StyleProp<TextStyle>;
  bottomLink?: StyleProp<TextStyle>;
  iconChevron?: StyleProp<ViewStyle>;
  bottomLinkBis?: StyleProp<ViewStyle>;
  bottomLinkRowIcon?: StyleProp<TextStyle>;
  bottomLinkRowText?: StyleProp<TextStyle>;
};

export const BudgetInsightAccountsListCardContent: React.FC<BudgetInsightAccountsListCardContentProps> = ({ contributionData, navigation }) => {
  const { formatCurrencyNumber, formatMessage, formatDate } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const isWeb = !(isMobile || isTablet);
  const style = useStyles(
    {
      theme,
      isWeb
    },
    {}
  );

  // Contribution TABLE

  const tableContributionData: TableCellInterface[][] = [];

  const contributionHeaderRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <Text
          style={{ textAlign: 'left' }}
          variant="t3"
          weight="bold">
          {formatMessage({
            id: 'saving_sub-title',
            values: {
              variable: formatDate({
                value: new Date()
              })
            }
          })}
        </Text>
      ),
      containerStyle: [style?.tableHead, style.tableHeadStart]
    },
    {
      type: TableCellTypeEnum.EMPTY,
      containerStyle: style?.tableHead
    },
    {
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <Text
          style={{ textAlign: 'left' }}
          variant="t3"
          weight="bold">
          {formatCurrencyNumber({ value: contributionData?.head.balance || 0 })}
        </Text>
      ),
      containerStyle: [style?.tableHead, style.tableHeadEnd]
    }
  ];

  // HEADER

  tableContributionData.push(contributionHeaderRow);

  // BODY
  contributionData?.body?.forEach((contribData, i, list) => {
    tableContributionData.push([
      {
        type: TableCellTypeEnum.ENTRIES,
        children: (
          <View
key={`contribFees_${i}`}
style={style.entrieColContainerStyle}>
            <View
              style={[
                style.entrieColPointStyle,
                {
                  width: theme.metrics.spacing.m,
                  height: theme.metrics.spacing.m,
                  borderRadius: theme.metrics.spacing.m / 2,
                  borderWidth: theme.metrics.spacing.xs,
                  borderColor: contribData.color
                }
              ]}
            />
            <Text
              style={style.entrieColTextStyle}
              variant="t3">
              {contribData.account}
            </Text>
          </View>
        ),
        containerStyle: style?.entrieColStyle
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            variant="t3"
            weight="light">
            {contribData.establishment}
          </Text>
        ),
        containerStyle: style?.colStyle
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <View style={{ flexDirection: 'row' }}>
            <Text
              variant="t3"
              weight="bold">
              {formatCurrencyNumber({ value: contribData.amount })}
            </Text>
          </View>
        ),
        containerStyle: style?.lastColStyle
      }
    ]);
  });

  tableContributionData.push([
    {
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <View style={style.entrieColContainerStyle}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(RouteNames.BiConsentment);
            }}
            style={style.bottomLinkBis}>
            <FontIcon
              name={IconEnum.AJOUTER}
              size={theme.metrics.iconSizes.l}
              style={style.bottomLinkRowIcon}
            />
            <Text style={style.bottomLinkRowText}>
              {formatMessage({ id: 'AgregationCardAjouterCompteExtBouton' })}
            </Text>
          </TouchableOpacity>
        </View>
      ),
      containerStyle: [style?.lastColStyle, style?.tableLastRowStart]
    },
    {
      type: TableCellTypeEnum.DEFAULT,
      containerStyle: [style?.colStyle]
    },
    {
      type: TableCellTypeEnum.DEFAULT,
      containerStyle: [style?.colStyle, style?.tableLastRowEnd]
    }
  ]);

  const tableCard = useMemo(
    () => (
      <View style={style.tableContainer}>
        <Table
          data={tableContributionData}
          data-testid='your_other_savings' />
        <Text
          style={style.legend}
          variant="t4"
          weight="light">
          {contributionData?.legend}
        </Text>
      </View>
    ),
    [style, contributionData, tableContributionData]
  );

  return <View style={style.mainContainer}>{tableCard}</View>;
};

export const getStyles = (context?: ContextType, style?: StyleType): StyleType => ({
  mainContainer: [
    {
      flexDirection: context?.isWeb ? 'row' : 'column'
    },
    style?.mainContainer
  ],
  tableContainer: [
    {
      flexDirection: 'column',
      padding: context?.theme.metrics.spacing.m,
      width: '100%'
    },
    style?.tableContainer
  ],
  tableHead: [
    {
      borderColor: context?.theme.colors.background.default.c500,
      width: '100%',
      alignItems: 'flex-start',
      backgroundColor: context?.theme.colors.background.default.c500
    },
    style?.tableHead
  ],
  tableHeadStart: [
    {
      borderTopStartRadius: 5,
      minWidth: '60%'
    },
    style?.tableHeadStart
  ],
  tableHeadEnd: [
    { minWidth: '40%', borderTopEndRadius: 5, alignItems: 'flex-end' },
    style?.tableHeadEnd
  ],
  tableLastRowStart: [
    {
      alignItems: 'flex-start',
      borderBottomStartRadius: 5,
      borderBottomEndRadius: 5,
      borderLeftWidth: 1,

      borderColor: context?.theme.colors.background.default.c500,
      minWidth: '100%'
    },
    style?.tableLastRowStart
  ],
  tableLastRowEnd: [
    {
      borderBottomEndRadius: 5,
      borderRightWidth: 1
    },
    style?.tableLastRowEnd
  ],
  button: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      flex: 1
    },
    !context?.isWeb && {
      paddingHorizontal: context?.theme?.metrics.spacing.s
    },
    style?.button
  ],
  contentStyle: [
    {
      flex: 1,
      flexDirection: 'column'
    },
    style?.contentStyle
  ],
  leftCard: [
    {
      alignItems: 'flex-start'
    },
    style?.leftCard
  ],
  ContainerStyle: [
    {
      flex: 1,
      paddingLeft: context?.theme.metrics.spacing.xxm,
      paddingTop: context?.theme.metrics.spacing.xm
    },
    style?.ContainerStyle
  ],
  headerTextStyle: [
    {
      textAlign: 'right'
    },
    style?.headerTextStyle
  ],
  entrieColStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      ...(context?.isWeb && { minWidth: '45%' }),
      paddingRight: context?.theme.metrics.spacing.m,
      borderRightWidth: 0
    },
    style?.entrieColStyle
  ],
  colStyle: [
    {
      alignItems: 'flex-end',
      borderLeftWidth: 0,
      borderRightWidth: 0,
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.colStyle
  ],
  lastColStyle: [
    {
      alignItems: 'flex-end',
      borderLeftWidth: 0,
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.lastColStyle
  ],
  entrieColContainerStyle: [
    {
      flexDirection: 'row',
      alignItems: "center"
    },
    style?.entrieColContainerStyle
  ],
  entrieColPointStyle: [
    {
      width: 13,
      height: 13,
      borderRadius: 50,
      borderWidth: 10,
      marginRight: context?.theme.metrics.spacing.s
    },
    style?.entrieColTextStyle
  ],
  entrieColTextStyle: [
    {
      textAlign: 'left', 
      flexShrink: 1
    },
    style?.entrieColTextStyle
  ],
  legend: [
    {
      alignSelf: 'flex-end',
      marginTop: context?.theme.metrics.spacing.s,
      color: context?.theme.colors.basics.grey.c500
    },
    style?.legend
  ],
  bottomLink: [
    {
      textDecorationLine: undefined,
      marginTop: context?.theme.metrics.spacing.xs
    },
    style?.bottomLink
  ],
  iconChevron: [
    {
      marginTop: context?.theme?.metrics.spacing.xxs,
      paddingLeft: context?.theme?.metrics.spacing.xxs
    },
    style?.iconChevron
  ],
  bottomLinkBis: [
    {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    style?.bottomLinkBis
  ],
  bottomLinkRowIcon: [
    {
      fontWeight: 'bold',
      paddingRight: context?.theme.metrics.spacing.s,
      color: context?.theme.colors.text.link.c500
    },
    style?.bottomLinkRowIcon
  ],
  bottomLinkRowText: [
    {
      color: context?.theme.colors.text.link.c500
    }
  ]
});

const useStyles = createUseStyles(getStyles);
