import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import { getProfitSharingIncentiveInitDataState } from '@modules/profit-sharing-incentive/selectors';

export const useController = () => {
  const navigation = useNavigation();

  const {
    availableContributionAmount = null,
    suggestedContributionAmount = null,
    isContributionTargetingOK = false,
    legalFramework,
    type
  } = useSelector(getProfitSharingIncentiveInitDataState) ?? {};

  const goToContribution = useCallback(() => {
    navigation.navigate(RouteNames.ContributionModal);
  }, [navigation]);

  const getIconName = useCallback(
    suggestedAmount => (suggestedAmount === null && IconEnum.INFO) || IconEnum.CONTRIBUTION,
    []
  );

  return {
    availableContributionAmount,
    suggestedContributionAmount,
    isContributionTargetingOK,
    legalFramework,
    type,
    goToContribution,
    getIconName
  };
};
