import React from 'react';
import {View, StyleProp, ViewStyle, ImageStyle, Platform, DimensionValue} from 'react-native';

import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

import { ImageManager } from '../ImageManager';
import { allSVGPaths } from './allSVGPaths';

interface Props {
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  name: string;
  containerStyle?: StyleProp<ViewStyle>;
  SVGStyle?: StyleProp<ImageStyle>;
}

export type SVGLocalLoaderStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  SVGStyle?: StyleProp<ImageStyle>;
};

export type StylesContext = {
  theme: ThemeType;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
};

export const SVGLocalLoader: React.FC<Props> = ({
  maxWidth = 400,
  maxHeight = 300,
  height = '100%',
  width = '100%',
  name,
  SVGStyle,
  containerStyle
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme, maxHeight, maxWidth, height, width },
    { containerStyle, SVGStyle }
  );
  const MyComponent = allSVGPaths[name];
  const hasProperty: boolean = MyComponent?.hasOwnProperty('imgType');
  const getImageSource = React.useCallback(() => {
    if (hasProperty) {
      if (Platform.OS === 'web') return MyComponent.img.default;
      return MyComponent.img;
    }
    return MyComponent;
  }, [hasProperty, MyComponent]);

  return (
    <View style={styles.containerStyle}>
      <ImageManager
        hasProperty={hasProperty}
        platform={Platform.OS}
        source={getImageSource()}
        SVGComponent={MyComponent}
        testID={'SVGImage_' + name}
      />
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: SVGLocalLoaderStyles
): SVGLocalLoaderStyles => ({
  containerStyle: [
    {
      flex: 1,
      width: context?.width as (DimensionValue | undefined),
      height: context?.height as (DimensionValue | undefined),
      maxHeight: context?.maxHeight as (DimensionValue | undefined),
      maxWidth: context?.maxWidth as (DimensionValue | undefined),
    },
    style?.containerStyle
  ],
  SVGStyle: [
    {
      flex: 1,
      resizeMode: 'contain',
      width: '100%',
      height: '100%'
    },
    style?.SVGStyle
  ]
});

const useStyles = createUseStyles(getStyles);
