import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  Text,
  useTranslation,
  useTheme,
  NavigationHeader,
  ColumnsContainer,
  ProfileBankAccountCard,
  SimpleButton,
  Link,
  FontIcon,
  Modal,
  RetryActionPlaceHolder,
  Spinner,
  StatusTag
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { BankOperationTypesList, PlanFamillyList } from '@constants/index';
import { IconEnum } from '@ere-uilib/enums';
import { StatusTagTypesEnum } from '@ere-uilib/molecules/tags/StatusTag/sharedInterfaces';
import { BankStatusTypesEnum } from '@modules/bank-details/constants';
import { AccountBankDetailsType } from '@modules/bank-details/types';

import { BankDetailsCopyIBANModal, MandateModal } from '../components';
import { Props } from './interfaces';
import { useStyles } from './useStyles';
import { useSelector } from 'react-redux';
import { AppState } from '@modules/index';
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";
import {AppNavigatorInterface} from "@navigation/Interfaces";

type ModalType = Omit<React.ComponentPropsWithoutRef<typeof BankDetailsCopyIBANModal>, 'onClose'>;

export const BankSummaryPageComponent: React.FC<Props> = ({
  onGetBankData,
  bankData,
  isLoadingBankData,
  bankDataError,
  onSetSelectedAccountType,
  onSetSelectedPlanFamily
}) => {
  const { formatMessage, getMessageRaw, formatDate } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
  const styles = useStyles();
  const [modalData, setModalData] = useState<ModalType | undefined>(undefined);
  const [isMandateModalVisible, setIsMandateModalVisible] = useState<boolean>(false);
  const [selectedPlanFamily, setSelectedPlanFamily] = useState<PlanFamillyList | undefined>();

  // TODO: remove after prod testing
  const RcActivateState = useSelector((state: AppState) => state.settings.applicationSettings.RcActivateState);

  useEffect(() => {
    onGetBankData();
  }, [onGetBankData]);

  const isEsEligible = bankData?.es !== null;
  const isRcEligible = bankData?.rc !== null;
  const eligibleDispositifsES = bankData?.es?.planList && bankData?.es?.planList.join(', ');
  const eligibleDispositifsRC = bankData?.rc?.planList && bankData?.rc?.planList.join(', ');

  const dictionnary = useMemo(() => {
    return {
      headerHelpPointContentHtml: getMessageRaw({ id: 'CoordonneesBancairesRecapHelpDescription' }),
      headerHelpPointModalTitle: formatMessage({ id: 'CoordonneesBancairesRecapHelpTitre' }),
      headerTitle: formatMessage({ id: 'DetailOperationPaimentIbanCoordonneesLabel' }),
      mandateTitle: formatMessage({ id: 'CoordonneesBancairesMandatTitre' }),
      CoordonneesBancairesMandatStatutLabel: formatMessage({
        id: 'CoordonneesBancairesMandatStatutLabel'
      }),
      CoordonneesBancairesMandatDateSignatureLabel: formatMessage({
        id: 'CoordonneesBancairesMandatDateSignatureLabel'
      }),
      CoordonneesBancairesMandatRUMLabel: formatMessage({
        id: 'CoordonneesBancairesMandatRUMLabel'
      }),
      CoordonneesBancairesMandatICSLabel: formatMessage({
        id: 'CoordonneesBancairesMandatICSLabel'
      }),
      mandateLinkTitle: formatMessage({ id: 'CoordonneesBancairesRecapMandatLien' }),
      CoordonneesBancairesRecapIBANBICTitre: formatMessage({
        id: 'CoordonneesBancairesRecapIBANBICTitre'
      }),
      CoordonneesBancairesRecapEpargneSalarialeESTitre: formatMessage({
        id: 'CoordonneesBancairesRecapEpargneSalarialeESTitre'
      }),
      CoordonneesBancairesRecapRetraiteCollectiveRCTitre: formatMessage({
        id: 'CoordonneesBancairesRecapRetraiteCollectiveRCTitre'
      })
    };
  }, [formatMessage, getMessageRaw]);

  const handleEdit = (planFamily: PlanFamillyList, plan: string, isRefund?: boolean) => {
    onSetSelectedPlanFamily(planFamily);
    if (planFamily === PlanFamillyList.RC) {
      navigation.navigate(RouteNames.BankDetailsStack, {
        screen: RouteNames.BankSetDocuments
      });
      return;
    }
    const operationType = isRefund
      ? BankOperationTypesList.REMBOURSEMENT
      : BankOperationTypesList.PRELEVEMENT;
    setModalData({ plan, operationType });
  };

  // TODO: provider to be removed once the tunnel advances
  const renderEmptyData = useCallback(
    (planFamily: PlanFamillyList, dispositifs: string, isRefund?: boolean) => {
      const accountType = isRefund
        ? BankOperationTypesList.VIREMENT
        : BankOperationTypesList.PRELEVEMENT;
      const cardTitle = isRefund
        ? 'CoordonneesBancairesRecapVirement'
        : 'CoordonneesBancairesRecapPrelevement';
      const buttonTitle = isRefund
        ? 'CoordonneesBancairesRecapAjouterCompteVirBouton'
        : 'CoordonneesBancairesRecapAjouterComptePrelBouton';
      return (
        <View style={styles.contentEmptyStyle}>
          <Text
            style={styles.firstLinesStyle}
            variant="t2"
            weight="regular">
            {formatMessage({ id: cardTitle })}
          </Text>
          <Text
            style={styles.informLinkStyle}
            variant="t2"
            weight="regular">
            {formatMessage({ id: 'CoordonneesBancairesRecapARenseigner' })}
          </Text>
          <SimpleButton
            containerStyle={styles.buttonStyle}
            design="outlined"
            isHoverableButton={false}
            leftIcon={IconEnum.AJOUTER}
            leftIconStyle={styles.leftIconStyle}
            onPress={() => {
              // todo: remove active state one prod test is done
            if (planFamily === PlanFamillyList.RC && RcActivateState === "Decrochage") {
                return handleRedirect(planFamily);
                }
              onSetSelectedAccountType(accountType);
              handleEdit(planFamily, dispositifs, isRefund);
            }}
            size="small"
            title={formatMessage({ id: buttonTitle })}
          />
        </View>
      );
    },
    [styles]
  );

  const handleRedirect = (provider: string) => {
    navigation.navigate(RouteNames.RedirectOperationPage, {
    provider,
    operationType: 'IBAN',
    });
    }
  const renderFilledData = useCallback(
    (
      bankData: AccountBankDetailsType | undefined | null,
      isRefund: boolean,
      planFamily: PlanFamillyList,
      dispositifs: string
    ) => {
      const cardTitle = isRefund
        ? 'CoordonneesBancairesRecapVirement'
        : 'CoordonneesBancairesRecapPrelevement';

      return (
        <View style={styles.contentFilledStyle}>
          <View style={styles.leftCard}>
            <Text
              style={styles.firstLinesStyle}
              variant="t2"
              weight="regular">
              {formatMessage({ id: cardTitle })}
            </Text>
            {bankData?.status?.includes(BankStatusTypesEnum.DEMANDE_INITIEE) ||
              bankData?.status?.includes(BankStatusTypesEnum.DEMANDE_EN_COURS) ? (
              <StatusTag
                containerStyle={styles.statusTag}
                label={formatMessage({ id: bankData?.statusLabel })}
                type={
                  bankData?.status === BankStatusTypesEnum.DEMANDE_EN_COURS
                    ? StatusTagTypesEnum.IN_PROGRESS
                    : StatusTagTypesEnum.EN_ATTENTE
                }
              />
            ) : (
              <>
                <Text
                  variant="t3"
                  weight="light">
                  {dictionnary.CoordonneesBancairesRecapIBANBICTitre}
                </Text>
                <Text
                  variant="t3"
                  weight="regular">
                  {bankData?.iban}
                </Text>
                {!isRefund && (
                  <View style={styles.spacingCardStyle}>
                    <Text
                      style={styles.firstLinesStyle}
                      variant="t3"
                      weight="regular">
                      {dictionnary.mandateTitle}
                    </Text>
                    <Text weight="light">
                      <Link
                        onPress={() => {
                          setIsMandateModalVisible(true);
                          setSelectedPlanFamily(planFamily);
                        }}
                        textStyle={styles.mandatLinkStyle}
                        textVariant="t3"
                        textWeight="light">
                        {dictionnary.mandateLinkTitle}
                        <View>
                          <FontIcon
                            color={theme.colors.basics.primary.c500}
                            name={IconEnum.CHEVRON_RIGHT}
                            size={theme.metrics.iconSizes.xxxs}
                            style={styles.chevronStyle}
                          />
                        </View>
                      </Link>
                    </Text>
                  </View>
                )}
              </>
            )}
          </View>
          {!bankData?.status?.includes(BankStatusTypesEnum.DEMANDE_EN_COURS) && (
            <TouchableOpacity
              onPress={() => {
                if (planFamily === PlanFamillyList.RC && RcActivateState === "Decrochage") {
                  return handleRedirect(planFamily);
                  }
                onSetSelectedAccountType(bankData?.type ?? '');
                handleEdit(planFamily, dispositifs, isRefund);
              }}
              style={styles.editButtonStyle}>
              <FontIcon
                color={theme?.colors.basics.primary.c500}
                name="editer"
                size={theme?.metrics.iconSizes.m}
              />
            </TouchableOpacity>
          )}
        </View>
      );
    },
    [styles, formatMessage, dictionnary, theme, handleEdit, onSetSelectedAccountType, RcActivateState]
  );

  const RenderHeader = useCallback(() => {
    return (
      <NavigationHeader
        displayBackButton={true}
        helpPoint={true}
        helpPointContentHtml={dictionnary.headerHelpPointContentHtml}
        helpPointModalTitle={dictionnary.headerHelpPointModalTitle}
        helpPointPosition={'right'}
        onGoBack={() =>
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.Profile
            }
          })
        }
        title={dictionnary.headerTitle}
      />
    );
  }, [
    dictionnary.headerHelpPointContentHtml,
    dictionnary.headerHelpPointModalTitle,
    dictionnary.headerTitle,
    navigation
  ]);

  const mandateModalcontent = useCallback(() => {
    const isSelectedPlanFamilyES = selectedPlanFamily === PlanFamillyList.ES;
    const bankDataSelectedPlanType = isSelectedPlanFamilyES ? bankData.es : bankData.rc;
    const accountDetailsHasIcs = !!bankDataSelectedPlanType?.debitBankAccountDetails?.ics;
    const accountDetailsHasRum = !!bankDataSelectedPlanType?.debitBankAccountDetails?.rum;

    return (
      <View style={styles.mandateModalContentWrapper}>
        <Text
          variant="t1"
          weight="bold">
          {dictionnary.mandateTitle}
        </Text>
        {isSelectedPlanFamilyES && (
          <View style={styles.mandateTextBlocWrapper}>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="light">
              {dictionnary.CoordonneesBancairesMandatStatutLabel}
            </Text>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="regular">
              {formatMessage({
                id: bankDataSelectedPlanType?.debitBankAccountDetails?.statusLabel ?? ''
              })}
            </Text>
          </View>
        )}
        {bankDataSelectedPlanType?.debitBankAccountDetails?.dateSignatureMandat && (
          <View style={styles.mandateTextBlocWrapper}>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="light">
              {dictionnary.CoordonneesBancairesMandatDateSignatureLabel}
            </Text>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="regular">
              {formatDate({
                value: bankDataSelectedPlanType?.debitBankAccountDetails?.dateSignatureMandat
              })}
            </Text>
          </View>
        )}
        {accountDetailsHasRum && (
          <View style={styles.mandateTextBlocWrapper}>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="light">
              {dictionnary.CoordonneesBancairesMandatRUMLabel}
            </Text>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="regular">
              {bankDataSelectedPlanType?.debitBankAccountDetails?.rum}
            </Text>
          </View>
        )}
        {accountDetailsHasIcs && (
          <View style={styles.mandateTextBlocWrapper}>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="light">
              {dictionnary.CoordonneesBancairesMandatICSLabel}
            </Text>
            <Text
              style={styles.mandateTextStyle}
              variant="t2"
              weight="regular">
              {bankDataSelectedPlanType?.debitBankAccountDetails?.ics}
            </Text>
          </View>
        )}
      </View>
    );
  }, [bankData, dictionnary, formatMessage, formatDate, styles]);

  const renderMandateModal = useCallback(() => {
    return (
      <MandateModal
        content={mandateModalcontent}
        isMandateModalVisible={isMandateModalVisible}
        onCloseModal={() => setIsMandateModalVisible(false)}
      />
    );
  }, [isMandateModalVisible, mandateModalcontent]);

  const renderSpinner = useCallback(() => {
    if (!isLoadingBankData) return null;

    return <Spinner />
  }, [isLoadingBankData]);

  const renderReTryAction = useCallback(() => {
    if (isLoadingBankData || !bankDataError?.message) return null;

    return (
      <RetryActionPlaceHolder
        retryAction={onGetBankData}
      />
    );
  }, [
    bankData,
    isLoadingBankData,
    onGetBankData
  ]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}>
        {renderReTryAction()}
        {renderSpinner()}
        {!isLoadingBankData && !bankDataError?.message && (
          <>
            {renderMandateModal()}
            <View style={styles.containerStyle}>
              <View style={styles.contentContainerStyle}>
                {isEsEligible && (
                  <ProfileBankAccountCard
                    cardTitle={dictionnary.CoordonneesBancairesRecapEpargneSalarialeESTitre}
                    eligibleDispositifs={eligibleDispositifsES ?? ''}
                    isES>
                    <View style={styles.separator} />
                    {/* TODO: refactor conditions */}
                    {bankData?.es?.debitBankAccountDetails?.bankAccountIsSet ||
                      bankData?.es?.debitBankAccountDetails?.status?.includes(
                        BankStatusTypesEnum.DEMANDE_INITIEE
                      ) ||
                      bankData?.es?.debitBankAccountDetails?.status?.includes(
                        BankStatusTypesEnum.DEMANDE_EN_COURS
                      )
                      ? renderFilledData(
                        bankData?.es?.debitBankAccountDetails,
                        false,
                        PlanFamillyList.ES,
                        eligibleDispositifsES ?? ''
                      )
                      : renderEmptyData(PlanFamillyList.ES, eligibleDispositifsES ?? '')}
                    <View style={styles.separator} />
                    {bankData?.es?.transferBankAccountDetails?.bankAccountIsSet ||
                      bankData?.es?.transferBankAccountDetails?.status?.includes(
                        BankStatusTypesEnum.DEMANDE_INITIEE
                      ) ||
                      bankData?.es?.transferBankAccountDetails?.status?.includes(
                        BankStatusTypesEnum.DEMANDE_EN_COURS
                      )
                      ? renderFilledData(
                        bankData.es?.transferBankAccountDetails,
                        true,
                        PlanFamillyList.ES,
                        eligibleDispositifsES ?? ''
                      )
                      : renderEmptyData(PlanFamillyList.ES, eligibleDispositifsES ?? '', true)}
                  </ProfileBankAccountCard>
                )}
                {isRcEligible && (RcActivateState === "On" || RcActivateState === "Decrochage") && (
                  <ProfileBankAccountCard
                    cardTitle={dictionnary.CoordonneesBancairesRecapRetraiteCollectiveRCTitre}
                    eligibleDispositifs={eligibleDispositifsRC ?? ''}
                    isES>
                    <View style={styles.separator} />
                    {bankData?.rc?.debitBankAccountDetails?.bankAccountIsSet
                      ? renderFilledData(
                        bankData?.rc?.debitBankAccountDetails,
                        false,
                        PlanFamillyList.RC,
                        eligibleDispositifsRC ?? ''
                      )
                      : renderEmptyData(PlanFamillyList.RC, eligibleDispositifsRC ?? '')}
                  </ProfileBankAccountCard>
                )}
              </View>
            </View>
            <Modal visible={!!modalData}>
              <BankDetailsCopyIBANModal
                {...modalData}
                onClose={() => {
                  setModalData(undefined);
                }}
              />
            </Modal>
          </>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
