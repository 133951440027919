import React, { useState, useEffect } from 'react';
import { View } from 'react-native';

import {
  DashboardAnnounce,
  InstallmentsStepper,
  Link,
  Text,
  NotificationHard,
  NotificationIconTypeEnum,
  SimpleIconButton,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { innerNavigations } from '@constants/savings';
import {
  DirectionEnum,
  StepperItemType,
  StepperSizeEnum,
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useScreenSizes, useTheme, createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { StepperStatus } from '@ere-uilib/types';
import { clearRefundData } from '@modules/refund/actions/refundActions';

import { RefundSuccessPageProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const RefundSuccessPageComponent: React.FC<RefundSuccessPageProps> = ({
  repartitions,
  onClearCacheAfterOperationDone,
  refundDemandStatus,
}) => {
  const { formatMessage, formatMessageWithJSXInsertion } = useTranslation();
  const [visibleNotification, setVisibleNotification] = useState(false);

  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles({ theme }, {});

  useEffect(() => {
    if (repartitions?.formValues?.totalAmount && repartitions?.totalAmount) {
      if (repartitions.formValues.totalAmount < repartitions.totalAmount) {
        setVisibleNotification(true);
      }
    }
  }, []);

  //mock data, to be deleted later
  const stepperItems: StepperItemType[] = [
    {
      status: StepperStatus.DONE,
      title: formatMessage({
        id: refundDemandStatus.operationEnded ?
          'Remboursement_ecranfinal_demandeAcceptee_titre' :
          'Remboursement_ecranfinal_demandeEnCours'
      }),
      children: (
        <Text
          style={styles.stepSubTextStyle}
          variant="t3"
          weight="light">
          {formatMessageWithJSXInsertion({
            id: refundDemandStatus.operationEnded ?
              'Remboursement_ecranfinal_demandeAcceptee_description' :
              'Remboursement_ecranfinal_demandeEnCours_description'
          }) + ' '}
          <Link
            onPress={() => {
              onClearCacheAfterOperationDone({
                selectedNavigationTab: innerNavigations[3],
                planId: repartitions?.planId,
                stack: RouteNames.BottomTabNavigator,
                screen: RouteNames.SavingsStack,
                actionType: clearRefundData(),
              });
            }}
            textVariant="t3"
            textWeight="regular"
          >
            {formatMessage({
              id: 'Remboursement_ecranfinal_cliquezIci_lien',
            })}
          </Link>
        </Text>
      ),
    },
    {
      status: StepperStatus.WAITING,
      title: formatMessage({ id: 'Remboursement_ecranfinal_operationTerminee_description' }),
    },
  ];

  const renderActions = () => {
    return (
      <>
        {visibleNotification && (
          <>
            <NotificationHard
              containerStyle={[
                styles.notificationContainerStyle,
                { marginBottom: theme.metrics.spacing.xm },
              ]}
              text={formatMessage({
                id: 'Remboursement_ecranfinal_card_description',
              })}
              title={formatMessage({
                id: 'Remboursement_ecranfinal_card_titre',
              })}
              type={NotificationIconTypeEnum.TIPS}
            />
          </>
        )}

        <SimpleIconButton
          containerStyle={styles.buttonNewRefund}
          design="outlined"
          onPress={() => {
            onClearCacheAfterOperationDone({
              stack: RouteNames.RefundsStack,
              screen: RouteNames.RefundOnboarding,
              actionType: clearRefundData(),
            });
          }}
          title={formatMessage({ id: 'Remboursement_Bouton_FinParcours' })}
        />
        <SimpleIconButton
          onPress={() => {
            onClearCacheAfterOperationDone({
              stack: RouteNames.BottomTabNavigator,
              screen: RouteNames.Home,
              actionType: clearRefundData(),
            });
          }}
          title={formatMessage({ id: 'Remboursement_Bouton_RetourAccueil' })}
          testId={locator._recover_savings._back_home}
        />
      </>
    );
  };

  return (
    <DashboardAnnounce
      actions={renderActions}
      description={
        <>
          <View style={styles.dashboardAnnonce}>
            <InstallmentsStepper
              containerStyle={styles.containerStepper}
              direction={DirectionEnum.COLUMN}
              stepperItems={stepperItems}
              stepperSize={
                isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG
              }
            />
          </View>
        </>
      }
      sourceName={'refundDone'}
      title={formatMessage({
        id: 'Remboursement_ecranfinalTitre_demandeAcceptee',
        values: { plan: repartitions?.planName },
      })}
    />
  );
};

const useStyles = createUseStyles(getStyles);
