import React from 'react';
import { View } from 'react-native';

import {
  Modal,
  ModalFrame,
  NotificationIcon,
  NotificationIconTypeEnum,
  Title,
} from '@components/index';
import {
  useTranslation,
  SimpleButton,

} from '@components/index';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import { RefundsRepartitionValidationModalProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const RefundsRepartitionValidationModal: React.FC<React.PropsWithChildren<RefundsRepartitionValidationModalProps>> = ({ visible, onClose, onSubmit }) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const style = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet,
    },
    {}
  );

  return  <Modal visible={visible}>
    <ModalFrame
      desktopPosition="center"
      mobilePosition="bottom"
      onClose={onClose}
    >
      <View style={style.closeRefundModal}>
        <NotificationIcon type={NotificationIconTypeEnum.TIPS}  />
        <View style={style.closeRefundModalTexts}>
          <Title
            style={style.closeRefundModalTitle}
            variant="t6"
            weight="bold"
          >
            {formatMessage({ id: 'Remboursement_modaleMontIncomplet_title' })}
          </Title>
          <Title variant="t7">
            {formatMessage({ id: 'Remboursement_modaleMontIncomplet_content' })}
          </Title>
        </View>
        <View style={style.closeRefundModalButtonContainer}>
          <SimpleButton
            containerStyle={style.closeRefundModalCancelButton}
            design="outlined"
            onPress={onSubmit}
            title={formatMessage({ id: 'Remboursement_modaleMontIncomplet_ValiderSaisie' })}
            testId={locator._recover_savings._confirm}
          />
          <SimpleButton
            onPress={onClose}
            title={
              formatMessage({ id: 'Remboursement_modaleMontIncomplet_ModifierSaisie' })
            }/>
        </View>
      </View>
    </ModalFrame>
  </Modal>;
};

const useStyles = createUseStyles(getStyles);
