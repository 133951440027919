import { label as common } from '../label';
import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentivePaymentModeTypeEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';
import {LegalFrameworkByType} from "@pages/ProfitSharingIncentive/types";

type Label = Omit<typeof common, 'header'> & {
  header: {
    title: string;
    help: {
      title: string;
      content: LegalFrameworkByType<string>;
    };
  };
  title: string;
  description: string;
  contribution: string;
  notification: {
    payment: Partial<{
      [key in ProfitSharingIncentivePaymentModeTypeEnum]: {
        description: string;
        link?: string;
      };
    }>;
    tax: {
      description: string;
      amount: string;
      link: string;
    };
    minimum: string;
  };
  button: {
    next: string;
  };
};

export const label: Label = {
  ...common,
  header: {
    ...common.header,
    title: 'PIDefinitionBesoinTitre',
    help: {
      ...common.header.help,
      content: {
        [ProfitSharingIncentiveTypeEnum.profitsharing]: {
          default: common.header.help.content
        },
        [ProfitSharingIncentiveTypeEnum.incentive]: {
          default: common.header.help.content,
          [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIChoixInvestPaimentPPVAide'
        }
      }
    }
  },
  title: 'PIDefinitionBesoinChoixDescription',
  description: 'PIDefinitionBesoinChoixInvestirPercevoirDescription',
  contribution: 'PIDefinitionBesoinAbondementContenu',
  notification: {
    payment: {
      [ProfitSharingIncentivePaymentModeTypeEnum.CHEQUE]: {
        description: 'PIDefinitionBesoinCardRIBManquantDescription',
        link: 'PIDefinitionBesoinCardRIBRenseignerRIBLien'
      },
      [ProfitSharingIncentivePaymentModeTypeEnum.COMPANY]: {
        description: 'PIDefinitionBesoinCardEntreprisePayeuseDescription'
      },
      [ProfitSharingIncentivePaymentModeTypeEnum.RIB]: {
        description: 'PIDefinitionBesoinCardRIBDisponibleDescription',
        link: 'PIDefinitionBesoinCardRIBDisponibleRIBLien'
      }
    },
    tax: {
      description: 'PIDefinitionBesoinCardImpotDescription',
      amount: 'PIDefinitionBesoinCardImpotMontantLabel',
      link: 'PIDefinitionBesoinCardImpotModiferLien'
    },
    minimum: 'PIChoixInvestMontantMiniDescription'
  },
  button: {
    next: 'PIDefinitionBesoinContinuerBouton'
  }
};
