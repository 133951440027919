import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { Platform, Pressable, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FontIcon,
  IconEnum,
  RadiosButtonList,
  SimpleButton,
  Text,
  Title,
  WebView,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index'
import { OtpChanelTypeEnum } from '@constants/profile'
import { PdfRenderer } from '@ere-uilib/atoms/PdfRenderer'
import { ShareDocumentButton } from '@ere-uilib/atoms/PdfViewer/components/ShareDocumentButton/ShareDocumentButton'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import {
  downloadDocumentMandatRCRequest,
  signMandatRCRequest
} from '@modules/bank-details/actions/actionsCreators'
import {
  getBankDocumentMandatRc,
  getBankInit,
  getUpdateFinishedStatus
} from '@modules/bank-details/selectors'
import { constructStepperItems } from '@pages/Common/utils'

import {
  BankDataHeader,
  BankDetailsErrorPopin,
  BankDetailsSuccessOrErrorPopin,
  MandateModal
} from '../components'
import { Props } from './interfaces'
import { useStyles } from './useStyles'
import { PopinContentTypeEnum } from '../enums/PopinContentTypeEnum'
import { RouteNames } from '@constants/navigation';

export const BankSignaturePageComponentRC: React.FC<React.PropsWithChildren<Props>> = ({
  handleDocumentMandatRCRequest,
  signMandatLoadingStatus
}) => {
  const { formatMessage } = useTranslation()
  const styles = useStyles()
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<string | undefined>()
  const [isMandateModalVisible, setIsMandateModalVisible] = useState<boolean>(false)
  const isPopinVisible = useSelector(getUpdateFinishedStatus)
  const bankInfo = useSelector(getBankInit)
  const bankDocumentMandatRc = useSelector(getBankDocumentMandatRc)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const { isMobile, isTablet } = useScreenSizes()
  const theme = useTheme()

  useEffect(() => {
    dispatch(downloadDocumentMandatRCRequest())
  }, [dispatch, handleDocumentMandatRCRequest])

  const stepperItems = constructStepperItems({
    size: 3,
    direction: DirectionEnum.ROW,
    activeStep: 3
  })

  const handleNextButton = useCallback(
    () => dispatch(signMandatRCRequest(selectedCommunicationType ?? '')),
    [dispatch, selectedCommunicationType]
  )

  const getRadioOptionsData = useCallback(
    () => [
      {
        value: OtpChanelTypeEnum.SMS_DOCAPOSTE,
        label: formatMessage({
          id: 'CoordonneesBancairesSMSSOTPEL',
          values: {
            numero_telephone: bankInfo.phoneNumber
          }
        })
      },
      {
        value: OtpChanelTypeEnum.MAIL_DOCAPOSTE,
        label: formatMessage({
          id: 'CoordonneesBancairesMAILOTPSEL',
          values: {
            email: bankInfo.mail
          }
        })
      }
    ],
    [bankInfo.mail, bankInfo.phoneNumber, formatMessage]
  )

  const handleGoBack = useCallback(() => {
    navigation.navigate(RouteNames.BankDetailsStack,{screen:RouteNames.BankSetIban });
  }, [navigation]);

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActionsStyle}>
        <View style={styles.bottomActionsButtonsContainerStyle}>
          <SimpleButton
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={handleGoBack}
          />
          <SimpleButton
            containerStyle={styles.simpleButtonStyle}
            design="solid"
            disabled={!selectedCommunicationType}
            loading={signMandatLoadingStatus}
            onPress={handleNextButton}
            size="small"
            title={formatMessage({ id: 'CoordonneesBancairesMandatBoutonRC' })}
          />
        </View>
      </View>
    ),
    [selectedCommunicationType, handleNextButton, formatMessage, navigation]
  )

  const mandateModalcontent = useCallback(() => {
    return (
      bankDocumentMandatRc && (
        <View>
          <PdfRenderer
            base64PDF={bankDocumentMandatRc}
            pdfStyle={styles.pdfStyle}
          />
        </View>
      )
    )
  }, [styles.pdfStyle, bankDocumentMandatRc])

  const renderLeftButton = useCallback(() => {
    return (
      bankDocumentMandatRc && (
        <ShareDocumentButton
          base64PDF={bankDocumentMandatRc}
          shareIconStyle={styles.shareIconStyle}
        />
      )
    )
  }, [bankDocumentMandatRc])

  const renderMandateModal = useCallback(() => {
    return (
      <MandateModal
        content={mandateModalcontent}
        isMandateModalVisible={isMandateModalVisible}
        onCloseModal={() => setIsMandateModalVisible(false)}
        renderLeftButton={renderLeftButton}
      />
    )
  }, [isMandateModalVisible, mandateModalcontent, renderLeftButton])

  const renderRecapPagePdf = useCallback(() => {
    return (
      <Pressable
        onPress={() => setIsMandateModalVisible(true)}
        style={styles.recapPageContainer}>
        <FontIcon
          color={theme.colors.text.primary.c500}
          name={'voir'}
          size={theme.metrics.iconSizes.xxm}
        />
      </Pressable>
    )
  }, [theme, setIsMandateModalVisible])

  const renderMainContent = useCallback(() => {
    return (
      <View style={styles.containerStyle}>
        <Title
          variant="t4"
          weight="bold">
          {formatMessage({ id: 'CoordonneesBancairesMandatTitreRC' })}
        </Title>
        <Text
          style={styles.descriptionStyle}
          variant="t3"
          weight="regular">
          {formatMessage({ id: 'CoordonneesBancairesMandatInfoRC' })}
        </Text>
        <RadiosButtonList
          activeRadio={selectedCommunicationType}
          containerStyle={styles.radioOptionsContainer}
          onChange={(data: string) => setSelectedCommunicationType(data)}
          options={getRadioOptionsData()}
        />
        <View style={styles.webviewContainer}>
          {bankDocumentMandatRc &&
            (Platform.OS === 'web' ? (
              <WebView
                sourceUri={{
                  uri: bankDocumentMandatRc
                }}
                style={styles.HtmlFrameStyle}
              />
            ) : (
              renderRecapPagePdf()
            ))}
        </View>
        <Text
          style={styles.descriptionStyle}
          variant="t3"
          weight="regular">
          {formatMessage({
            id: 'CoordonneesBancairesMandatPiedPageRC'
          })}
        </Text>
        {!isMobile && !isTablet && renderBottomActions()}
        {renderMandateModal()}
      </View>
    )
  }, [
    selectedCommunicationType,
    isMobile,
    isTablet,
    bankDocumentMandatRc,
    setSelectedCommunicationType,
    getRadioOptionsData,
    renderRecapPagePdf,
    renderBottomActions,
    renderMandateModal
  ])

  const renderHeader = useCallback(() => {
    return (
      <BankDataHeader
        displayBackButton
        displayCloseButton
        helpPoint={false}
        stepperItems={stepperItems}
        title={formatMessage({ id: 'CoordonnesBancairesHeaderTitlePRELEVEMENT' })}
      />
    )
  }, [stepperItems])

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderBottomActions}>
        {renderMainContent()}
        <BankDetailsSuccessOrErrorPopin
          isModalVisible={isPopinVisible === true}
          quickSignEnumMember={
            isPopinVisible === true ? PopinContentTypeEnum.SUCCESS : PopinContentTypeEnum.UNKNOWN
          }
        />
        <BankDetailsErrorPopin />
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}
