import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ExpendableGreen,
  ChequeInfosCard,
  BankAccountInfosCard,
  Paragraph,
  NotificationHard,
  NotificationIconTypeEnum,
  OperationsHistoryStatusEnum
} from '@components/index';
import {
  OperationDetailsPaymentModeTypeEnum,
} from '@constants/savings';
import { OperationDetailsDestinationPayment } from '@modules/savings/types';

import { useCards, useData, useLabel } from './hooks';
import {
  FiletStepperCards,
  FiletStepperCardAccounts,
  FiletStepperCardOrigin
} from '../FiletStepperCard';
import { NotificationReminderCancelable } from './NotificationReminderCancelable';
import { useStyles } from './useStyles';

export const OperationDetailsContent: React.FC = () => {
  const styles = useStyles();

  const {
    companyName,
    deadline,
    remainingDays,
    netAmount,
    originTitleLabelCode,
    source,
    destination,
    statusCode,
    conditionalOrder,
    isCancelable,
    isAmountHidden,
    isIncentive,
    isRefund,
    isArbitration,
    isInstallment,
    applicationSettings,
    isCapitalizedDividend,
  } = useData();

  const { getCards } = useCards();
  const { getLabel } = useLabel();

  const renderReminderCancelable = useCallback(() => {
    if (!isCancelable) return
    return (
      <NotificationReminderCancelable
        deadline={deadline}
        remainingDays={remainingDays}
      />
    )
  }, [isCancelable, deadline, remainingDays]);

  const renderOrigin = useCallback(() => {
    if (netAmount === undefined) return
    return (
      <FiletStepperCardOrigin
        amount={netAmount}
        description={companyName}
        title={originTitleLabelCode}
      />
    )
  }, [netAmount, companyName, originTitleLabelCode]);

  const renderNotification = useCallback(() => {
    if (statusCode !== OperationsHistoryStatusEnum.IN_PROGRESS || !conditionalOrder?.thresholdType)
      return;

    return (
      <NotificationHard
        containerStyle={styles.notificationHard}
        text={getLabel().notificationInProgress}
        type={NotificationIconTypeEnum.WARNING}
      />
    );
  }, [statusCode, conditionalOrder, styles, getLabel]);

  const renderSource = useCallback(() => {
    if (!source?.plans?.length) return;

    const { cards } = getCards(source?.plans, 'SOURCE');

    return (
      <FiletStepperCards
        cards={cards}
        conditionalOrder={conditionalOrder}
        disableLine={!source?.payment}
        statusCode={statusCode}
      />
    );
  }, [conditionalOrder, getCards, source, statusCode]);

  const renderDestination = useCallback(() => {
    if (!destination?.plans?.length) return;

    const { cards } = getCards(destination?.plans, 'DESTINATION');
    return (
      <FiletStepperCards
        cards={cards}
        displayFiletTitle={!isCapitalizedDividend}
        disableLine={!destination?.payment}
      />
    );
  }, [destination, getCards, isCapitalizedDividend]);

  const renderAccounts = useCallback(
    (
      payment: OperationDetailsDestinationPayment | null | undefined,
      position: 'SOURCE' | 'DESTINATION'
    ) => {
      if (!payment) return;

      const label = getLabel(position);

      let PrePaymentDetails: React.ReactNode = null;
      let PaymentDetails: React.ReactNode = null;

      switch (payment.savers.type) {
        case OperationDetailsPaymentModeTypeEnum.CHEQUE:
          const address = payment.savers.fullAddress
          PrePaymentDetails = position === 'DESTINATION' && (
            <>
              <Paragraph
                variant='t3'
                weight='light'>
                {label[OperationDetailsPaymentModeTypeEnum.CHEQUE].prePaymentDetailsTitle}
              </Paragraph>
              <Paragraph
                autoMargin
                variant='t3'
                weight='light'>
                {label[OperationDetailsPaymentModeTypeEnum.CHEQUE].prePaymentDetailsSubtitle}
              </Paragraph>
            </>
          )
          PaymentDetails = (
            <ExpendableGreen
              title={label[OperationDetailsPaymentModeTypeEnum.CHEQUE].paymentDetailsTitle}>
              <View style={styles.paymentDetailContainer}>
                <ChequeInfosCard
                  appSettings={applicationSettings}
                  chequeInfoData={{
                    ...address,
                    additionalAddress_2: address.additionalAddress_2 || '',
                    additionalAddress_3: address.additionalAddress_3 || '',
                    street: address.street || '',
                    recipient: address.recipient || ''
                  }}
                />
                {position === 'DESTINATION' && (
                  <Paragraph
                    variant='t3'
                    weight='light'>
                    {label[OperationDetailsPaymentModeTypeEnum.CHEQUE].paymentDetailsSubtitle}
                  </Paragraph>
                )}
              </View>
            </ExpendableGreen>
          )
          break
        case OperationDetailsPaymentModeTypeEnum.VIREMENT:
          const bankAccountDetail = payment.savers.bankAccountDetail
          if (!bankAccountDetail.iban) break
          PrePaymentDetails = position === 'DESTINATION' && (
            <>
              <Paragraph
                variant='t3'
                weight='light'>
                {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].prePaymentDetailsTitle}
              </Paragraph>
              <Paragraph
                autoMargin
                variant='t3'
                weight='light'>
                {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].prePaymentDetailsSubtitle}
              </Paragraph>
            </>
          )
          PaymentDetails = (
            <ExpendableGreen
              title={label[OperationDetailsPaymentModeTypeEnum.VIREMENT].paymentDetailsTitle}>
              <View style={styles.paymentDetailContainer}>
                <BankAccountInfosCard
                  AccountInfoData={{
                    ...bankAccountDetail,
                    domiciliation: bankAccountDetail?.domiciliation || undefined,
                    titulaire: bankAccountDetail?.titulaire || undefined
                  }}
                  NoDisplayPaymentChoice
                />
                <Paragraph
                  autoMargin
                  variant='t3'
                  weight='light'>
                  {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].paymentDetailsSubtitle1}
                </Paragraph>
                {position === 'DESTINATION' && (
                  <>
                    <Paragraph
                      variant='t3'
                      weight='light'>
                      {label[OperationDetailsPaymentModeTypeEnum.VIREMENT].paymentDetailsSubtitle2}
                    </Paragraph>
                  </>
                )}
              </View>
            </ExpendableGreen>
          )
          break
        default:
          break
      }

      return (
        <FiletStepperCardAccounts
          amount={payment?.amount || 0}
          description={label[payment.savers.type]?.title || ''}
          disableLine={position !== 'SOURCE'}
          hideAmount={isAmountHidden}
          stepTitle={label.stepTitle}
          title={label[payment.savers.type]?.description || ''}>
          {(!!PrePaymentDetails || !!PaymentDetails) && (
            <View style={styles.bankAccountContainer}>
              {(isIncentive || isRefund) && PrePaymentDetails}
              {PaymentDetails}
            </View>
          )}
        </FiletStepperCardAccounts>
      );
    },
    [getLabel, isAmountHidden, styles, isIncentive, isRefund, applicationSettings]
  );

  return (
    <>
      {isIncentive && renderReminderCancelable()}
      {
        // Hide this for now
        // isIncentive && renderNotificationExternalPlan()
      }
      {(isRefund || isArbitration) && renderNotification()}
      {isIncentive && renderOrigin()}
      {(isRefund || isArbitration) && renderSource()}
      {isInstallment && renderAccounts(source?.payment, 'SOURCE')}
      {!isRefund && renderDestination()}
      {(isIncentive || isRefund) && renderAccounts(destination?.payment, 'DESTINATION')}
    </>
  );
}
